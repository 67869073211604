import React from "react";
import '../../css/styles.css';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';

function CommonIntro(props) {
    return (
        <>
            <Container className="bg-dark mt-4">
                <Card>
                    <Card.Body>
                        <Card.Title as="h1">{props.title}</Card.Title>
                        <Card.Text>{props.subheading}</Card.Text> 
                    </Card.Body>
                </Card>
            </Container>
        </>
    );
}

export default CommonIntro;