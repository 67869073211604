function formatPhoneNumber(value) {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;
  
    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, '');
  
    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;
  
    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early
  
    if (phoneNumberLength < 4) return phoneNumber;
  
    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
  
    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
}

function validatePhone (e, setPhoneNumber) {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setPhoneNumber(formattedPhoneNumber);
}

function validateTextOnly (e, setInput) {
    const inputValue = e.target.value;
    if (!inputValue || inputValue === '') { setInput(""); }
    else {
        if(/^[A-Za-z\s]+$/.test(inputValue)) {
            setInput(inputValue);
        }
    }
}

function validateTextAndNumbersOnly (e, setInput) {
    const inputValue = e.target.value;
    if (!inputValue || inputValue === '') { setInput(""); }
    else {
        if(/^[A-Za-z0-9]+$/.test(inputValue)) {
            setInput(inputValue);
        }
    }
}

function isEmail (email) {
    if (/^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/.test(email)) { return true }
    return false;
}

function isEduEmail (email) {
    if (/^[-\w.]+@([-\w]+\.)*[-\w]+\.edu$/.test(email)) { return true }
    return false;
}

function validatePersonalEmail (e, setMessage) {
    if (!isEmail(e.target.value)) {
        setMessage("Please enter a valid personal email address!");
    }
    else {
        setMessage("");
    }
}

function validateEduEmail (e, setMessage) {
    if (!isEduEmail(e.target.value)) {
        setMessage("Please enter a valid email address ending in .edu!");
    }
    else {
        setMessage("");
    }
}

function updateEduEmail (e, setInput) {
    setInput(e.target.value);
}

function updatePersonalEmail (e, setInput) {
    setInput(e.target.value);
}

function validateStep1 (values, setMessage) {
    if (values.firstName.length <= 0) {
        setMessage("First name must be entered. ");
        return false;
    }
    if (values.lastName.length <= 0) {
        setMessage("Last name must be entered. ");
        return false;
    }
    if (values.personalEmail.length <= 6) {
        setMessage("Please enter a valid personal email. ");
    }

    const formattedNumber = values.phoneNumber;
    const phoneNumber = formattedNumber.replace(/[^\d]/g, '');

    if (phoneNumber.length !== 10) {
        setMessage("A 10 digit phone number must be entered. ");
        return false;
    }
    if (values.phoneCarrier.length <= 0) {
        setMessage("Please select a phone carrier. ");
        return false;
    } 
    if (values.phoneType.length <= 0) {
        setMessage("Please select a phone type. ");
        return false;
    }
    return true;
}

function validateLooperStep2 (values, setMessage) {
    if (values.school === "") {
        setMessage("Please select your school or 'other'. ");
        return false;
    }
    if (values.major.length <= 1) {
        setMessage("Please tell us your major. ")
        return false;
    }
    if (values.gradYear.length !== 4) {
        setMessage("Please select your 4 digit graduation year. ");
        return false;
    }
    if (values.eduEmail.length <= 6) {
        setMessage("Please enter your school email. ");
        return false;
    }
    return true;
}

function validatePatronStep2 (values, setMessage) {
    if (values.gender === "") {
        setMessage("Please make a gender selection. ");
        return false;
    }
    if (values.addressType.length <= 0) {
        setMessage("Please select the type of address. ");
        return false;
    }
    if (values.address.length <= 15) {
        setMessage("Please select a valid address. ");
        return false;
    }
    if (values.solicitation === "") {
        setMessage("Please opt in or out of being a Looper resource. ");
        return false;
    }
    return true;
}

function validateLooperStep3 (values, setMessage) {
    if (values.gender === "") {
        setMessage("Please make a gender selection. ");
        return false;
    }
    if (values.citizenship === "") {
        setMessage("Please make a citizenship selection. ");
        return false;
    }
    if (values.citizenship === "No") {
        setMessage("You must be a US Citizen to continue at this time. ");
        return false;
    }
    if (values.hasTruck === "") {
        setMessage("Please answer the vehicle question. ");
        return false;
    }
    if (values.addressType.length <= 0) {
        setMessage("Please select the type of address. ");
        return false;
    }
    if (values.address.length <= 15) {
        setMessage("Please select a valid address. ");
        return false;
    }
    return true;
}

function validateStep4 (values, setMessage) {
    if (values.password.length <= 7) {
        setMessage("Please enter a valid password. ");
        return false;
    }
    if (values.confirmation !== values.password) {
        setMessage("The passwords do not match. ");
        return false;
    }  
    if (values.sourceID === "") {
        setMessage("Please tell us how you heard about us. ");
        return false;
    }  
    return true;
}

function validateLooperSubmission (values, setMessage, step) {
    switch (step) {
        case 1:
            if (validateStep1(values, setMessage)) { return true; }
            else { return false; }
        case 2:
            if (validateLooperStep2(values, setMessage)) { return true; }
            else { return false; }
        case 3:
            if (validateLooperStep3(values, setMessage)) { return true; }
            else { return false; }
        case 4:
            if (validateStep4(values, setMessage)) { return true; }
            else { return false; }
        case 5:
            if (validateStep1(values, setMessage) && validateLooperStep2(values, setMessage) && validateLooperStep3(values, setMessage) && validateStep4(values, setMessage)) { return true; }
            else { return false; }
        default:
            return false;
    }
}

function validatePatronSubmission (values, setMessage, step) {
    switch (step) {
        case 1:
            if (validateStep1(values, setMessage)) { return true; }
            else { return false; }
        case 2:
            if (validatePatronStep2(values, setMessage)) { return true; }
            else { return false; }
        case 3:
            if (validateStep4(values, setMessage)) { return true; }
            else { return false; }
        case 4:
            if (validateStep1(values, setMessage) && validatePatronStep2(values, setMessage) && validateStep4(values, setMessage)) { return true; }
            else { return false; }
        default:
            return false;
    }
}

const exports = {
    validatePhone,
    validateTextOnly,
    validatePersonalEmail,
    updatePersonalEmail,
    validateEduEmail,
    updateEduEmail,
    validatePatronSubmission,
    validateLooperSubmission,
    validateTextAndNumbersOnly
}

export default exports;