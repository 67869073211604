import Form from 'react-bootstrap/Form';

function FormInput(props) {
    return (
        <>
            <Form.Group className="mb-3">
                <Form.Label className="px-1 fw-bold">{props.label}</Form.Label>
                <Form.Control type="text" placeholder={props.placeholder} value={props.value} onChange={props.onChange} />
                <Form.Text className="text-muted">{props.subText}</Form.Text>
            </Form.Group>
        </>
    );
}

export default FormInput;