function formatPhoneNumber(value) {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;
  
    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, '');
  
    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;
  
    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early
  
    if (phoneNumberLength < 4) return phoneNumber;
  
    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
  
    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
}

function validatePhone (e, setPhoneNumber) {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setPhoneNumber(formattedPhoneNumber);
}

function validateTextOnly (e, setInput) {
    const inputValue = e.target.value;
    if (!inputValue || inputValue === '') { setInput(""); }
    else {
        if(/^[A-Za-z\s]+$/.test(inputValue)) {
            setInput(inputValue);
        }
    }
}

function validateTextAndNumbersOnly (e, setInput) {
    const inputValue = e.target.value;
    if (!inputValue || inputValue === '') { setInput(""); }
    else {
        if(/^[A-Za-z0-9]+$/.test(inputValue)) {
            setInput(inputValue);
        }
    }
}

function validateStep1 (values, setMessage) {
    if (values.jobTypeID === "") {
        setMessage("Please select a job type. ");
        return false;
    }
    if (values.jobDescription.length <= 0) {
        setMessage("A description must be entered. ");
        return false;
    }
    return true;
}

function validateStep2 (values, setMessage) {
    if (values.dates.length <= 0) {
        setMessage("Please elect 1 or more dates. ");
        return false;
    }
    return true;
}

function validateStep3 (values, setMessage) {
    if (values.outdoors === "") {
        setMessage("Please select an environment. ");
        return false;
    }
    if (values.truck === "") {
        setMessage("Please make a vehicle need selection. ");
        return false;
    }
    return true;
}

function validateStep4 (values, setMessage) {
    if (values.jobAddressTypeID === "") {
        setMessage("Please select the type of address. ");
        return false;
    }
    if (values.jobAddress.length <= 15) {
        setMessage("Please select a valid address. ");
        return false;
    }
    
    const formattedNumber = values.jobPhone;
    const phoneNumber = formattedNumber.replace(/[^\d]/g, '');

    if (phoneNumber.length !== 10) {
        setMessage("A 10 digit phone number must be entered. ");
        return false;
    }
    if (values.jobPhoneTypeID === "") {
        setMessage("Please select a phone type. ");
        return false;
    }
    
    return true;
}

function validateJobSubmission (values, setMessage, step) {
    switch (step) {
        case 1:
            if (validateStep1(values, setMessage)) { return true; }
            else { return false; }
        case 2:
            if (validateStep2(values, setMessage)) { return true; }
            else { return false; }
        case 3:
            if (validateStep3(values, setMessage)) { return true; }
            else { return false; }
        case 4:
            if (validateStep4(values, setMessage)) { return true; }
            else { return false; }
        case 5:
            if (validateStep1(values, setMessage) && validateStep2(values, setMessage) && validateStep3(values, setMessage) && validateStep4(values, setMessage)) { return true; }
            else { return false; }
        default:
            return false;
    }
}

const exports = {
    validatePhone,
    validateTextOnly,
    validateTextAndNumbersOnly,
    validateJobSubmission
}

export default exports;