import hookFunctions from '../../functions/signupFunctions';
import FormEmail from './components/formEmail';
import FormInput from './components/formInput';
import FormPhone from './components/formPhone';
import FormAddress from './components/formAddress';
import FormButton from './components/formButton';
import FormSchoolPicker from './components/formSchoolPicker';
import FormAddressTypePicker from './components/formAddressTypePicker';
import FormPhoneTypePicker from './components/formPhoneTypePicker';
import FormPhoneCarrierPicker from './components/formPhoneCarrierPicker';
import FormEduEmail from './components/formEduEmail';
import FormGradYear from './components/formGradYear';
import FormGender from './components/formGender';
import FormCitizenship from './components/formCitizenship';
import FormHasTruck from './components/formHasTruck';
import FormPassword from './components/formPassword';
import FormLooperSignupReview from './components/formLooperSignupReview';
import FormSourcePicker from './components/formSourcePicker';
import {useState} from 'react';
import * as sys from '../../config/variables';

function LooperSignupMultiStep(props) {
    const [step, setStep] = useState(1);
    const [validatedEntries, setValidatedEntries] = useState({});
    
    // Step 1 Form Inputs
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [personalEmail, setPersonalEmail] = useState("");
    const [phoneTypeID, setPhoneTypeID] = useState("");
    const [phoneTypeName, setPhoneTypeName] = useState("");
    const [carrierName, setCarrierName] = useState("");
    const [carrierID, setCarrierID] = useState("");
    
    // Step 2 Form Inputs
    const [schoolName, setSchoolName] = useState("");
    const [schoolID, setSchoolID] = useState("");
    const [gradYear, setGradYear] = useState("");
    const [major, setMajor] = useState("");
    const [eduEmail, setEduEmail] = useState("");
    
    // Step 3 Form Inputs
    const [gender, setGender] = useState("");
    const [genderSpelled, setGenderSpelled] = useState("");
    const [citizenship, setCitizenship] = useState("");
    const [hasTruck, setHasTruck] = useState("");
    const [addressTypeID, setAddressTypeID] = useState("");
    const [addressTypeName, setAddressTypeName] = useState("");
    const [address, setAddress] = useState("");

    // Step 4 Form Inputs
    const [password, setPassword] = useState("");
    const [confirmationPassword, setConfirmationPassword] = useState("");
    const [referralCode, setReferralCode] = useState("");
    const [sourceID, setSourceID] = useState("");
    const [sourceName, setSourceName] = useState("");
    
    // go back to previous step
    let prevStep = () => {
        if (step - 1 >= 1) {
            props.setMessage("");  
            setStep(step - 1);
        }
    }

    // proceed to the next step
    let nextStep = () => {

        if (step + 1 <= 5) {
            props.setMessage("");
            setStep(step + 1);
        }  
    }

    // for continue event listener
    let Continue = e => {
        e.preventDefault();
        switch (step) {
          case 1:
            if (hookFunctions.validateLooperSubmission({
              firstName: firstName,
              lastName: lastName,
              phoneNumber: phoneNumber,
              phoneCarrier: carrierID,
              phoneType: phoneTypeID,
              personalEmail: personalEmail,
            }, props.setMessage, step)) {
              nextStep();
            }
            break;
          case 2:
            if (hookFunctions.validateLooperSubmission({
              school: schoolID,
              major: major,
              gradYear: gradYear,
              eduEmail: eduEmail
            }, props.setMessage, step)) {
              nextStep();
            }
            break;
          case 3:
            if (hookFunctions.validateLooperSubmission({
              gender: gender,
              citizenship: citizenship,
              hasTruck: hasTruck,
              addressType: addressTypeID,
              address: address.label
            }, props.setMessage, step)) {
              nextStep();
            }
            break;
          case 4:
            if (hookFunctions.validateLooperSubmission({
              password: password,
              confirmation: confirmationPassword,
              referralCode: referralCode,
              sourceID: sourceID
            }, props.setMessage, step)) {
              setValidatedEntries({
                firstName: firstName,
                lastName: lastName,
                personalEmail: personalEmail,
                phoneNumber: phoneNumber,
                phoneCarrier: carrierID,
                carrierName: carrierName,
                phoneType: phoneTypeID,
                phoneTypeName: phoneTypeName,
                schoolID: schoolID,
                schoolName: schoolName,
                major: major,
                gradYear: gradYear,
                eduEmail: eduEmail,
                gender: gender,
                genderName: genderSpelled,
                citizenship: citizenship,
                hasTruck: hasTruck,
                addressType: addressTypeID,
                addressTypeName: addressTypeName,
                address: address.label,
                password: password,
                confirmation: confirmationPassword,
                referralCode: referralCode,
                sourceID: sourceID,
                sourceName: sourceName
              });
              nextStep();
            }
            break;
          default:
            break;
        }
    };

    let validateAndSubmit = (e) => {
      e.preventDefault();
      const validation = hookFunctions.validateLooperSubmission(validatedEntries, props.setMessage, step);
      if (validation === true) {
        const formattedNumber = phoneNumber;
        const cleanedPhoneNumber = formattedNumber.replace(/[^\d]/g, '');
        props.setValues({ 
          firstName: firstName,
          lastName: lastName,
          personalEmail: personalEmail,
          phoneNumber: cleanedPhoneNumber,
          phoneCarrier: carrierID,
          phoneType: phoneTypeID,
          schoolID: schoolID,
          major: major,
          gradYear: gradYear,
          eduEmail: eduEmail,
          gender: gender,
          citizenship: citizenship,
          hasTruck: hasTruck,
          addressType: addressTypeID,
          address: address.label,
          password: password,
          confirmation: confirmationPassword,
          referralCode: referralCode,
          sourceID: sourceID,
          sourceName: sourceName
        });
      }
    }

    switch(step) {
        case 1: // Personal Details  
          return (
            <>
              <FormInput label="First Name" placeholder="Enter your first name here..." value={firstName} subText="" onChange={(e) => hookFunctions.validateTextOnly(e, setFirstName)} />
              <FormInput label="Last Name" placeholder="Enter your last name here..." value={lastName} onChange={(e) => hookFunctions.validateTextOnly(e, setLastName)} subText="" /> 
              <FormEmail label="Enter your Personal Email" subText="We will validate this email in a future step.  FYI: We will never share your info with anyone else." onChange={(e) => hookFunctions.updatePersonalEmail(e, setPersonalEmail)} onBlur={(e) => hookFunctions.validatePersonalEmail(e, props.setMessage)} value={personalEmail} placeholder="johnsmith@example.com" /> 
              <FormPhoneTypePicker label="What is the type of Phone?" instructions="Select type of phone..." setPhoneTypeID={setPhoneTypeID} value={phoneTypeID} showMe={phoneTypeName} setPhoneTypeName={setPhoneTypeName}/>
              <FormPhone header="Personal Phone Number" subText="Enter your 10 digit phone number here.  We prefer you use your mobile number as we will use it to contact you during jobs and will send you occassional notifications via SMS that may be time-sensitive." value={phoneNumber} onChange={(e) => hookFunctions.validatePhone(e, setPhoneNumber)} />
              <FormPhoneCarrierPicker label="What is the carrier for that phone?" instructions="Select your phone carrier..." setCarrierID={setCarrierID} value={carrierID} showMe={carrierName} setCarrierName={setCarrierName} />
              <FormButton onClick={Continue} label="Continue" disabled={sys.maintenance || !sys.looperRegistration} />
            </>
          );
        case 2: // School Details
          return (
            <>
              <FormSchoolPicker label="School" instructions="Select a school or other below..." value={schoolID} setSchoolID={setSchoolID} setSchoolName={setSchoolName} showMe={schoolName} />
              <FormInput label="School Major" placeholder="Major..." value={major} onChange={(e) => hookFunctions.validateTextOnly(e, setMajor)} subText="" />
              <FormGradYear label="Graduation Year" instructions="Select your anticipated graduation year..." value={gradYear} setGradYear={setGradYear} showMe={gradYear} />
              <FormEduEmail label="Enter your School Email" subText="We will confirm your student status in a future step using this email address.  FYI: We will never share your info with anyone else." onChange={(e) => hookFunctions.updateEduEmail(e, setEduEmail)} onBlur={(e) => hookFunctions.validateEduEmail(e, props.setMessage)} value={eduEmail} placeholder="example@yourschool.edu" />
              <FormButton onClick={Continue} label="Continue" disabled={sys.maintenance || !sys.looperRegistration} />
              <FormButton onClick={prevStep} label="Previous Step" />
            </>
          );
        case 3: // Suppliments
          return (
              <>
                <FormGender heading="Select your Gender" groupName="gender" value={gender} setInput={setGender} setGenderName={setGenderSpelled}/>
                <FormCitizenship heading="Citizenship Status" groupName="citizenship" subText="At this time, you must be a US Citizen to be a Looper" value={citizenship} setInput={setCitizenship} />
                <FormHasTruck heading="Have a vehicle?" groupName="hasTruck" subText="Often times, a job requires Loopers to haul away items or use their vehicle to help a Patron on the job. Only answer yes above if you are willing to use your vehicle in those situations. Loopers get a bonus for each job that requires their vehicle!" value={hasTruck} setInput={setHasTruck} />
                <FormAddressTypePicker label="What is the type of Address?" instructions="Select the address type..." value={addressTypeID} setAddressTypeID={setAddressTypeID} setAddressTypeName={setAddressTypeName} showMe={addressTypeName} />
                <FormAddress label="Permanent Address" placeholder="Start to enter your address here..." value={address} onChange={setAddress} subText="Enter your address above until you see your address show up from the list of possible choices and then physically click it to designate it as your permanent address." />
                <FormButton onClick={Continue} label="Continue" disabled={sys.maintenance || !sys.looperRegistration} />
                <FormButton onClick={prevStep} label="Previous Step" />
              </>
          );
        case 4: // Account Details
          return (
            <>
              <FormPassword label="Choose your Password" subText="Minimum of 1 Uppercase Letter, 1 Lowercase Letter, 1 Number and 8 Total Characters.  The use of a symbol is highly advisable." onChange={(e) => setPassword(e.target.value)} value={password} />
              <FormPassword label="Repeat that Password" subText="The passwords must match." onChange={(e) => setConfirmationPassword(e.target.value)} value={confirmationPassword} />
              <FormInput label="Where you referred by someone we know?" subText="We pay referral bonuses! Use their referral code above to qualify for a bonus!" onChange={(e) => hookFunctions.validateTextAndNumbersOnly(e, setReferralCode)} value={referralCode} placeholder="Optional" />
              <FormSourcePicker label="How did you hear about us?" instructions="Select a source or other..." value={sourceID} setSourceID={setSourceID} setSourceName={setSourceName} showMe={sourceName} />
              <FormButton onClick={Continue} label="Continue" disabled={sys.maintenance || !sys.looperRegistration} />
              <FormButton onClick={prevStep} label="Previous Step" />
            </>
          );
        case 5: // Submit and Confirmation
            return (
              <>
                <FormLooperSignupReview values={validatedEntries} />
                <FormButton onClick={validateAndSubmit} label="Confirm and Continue" disabled={sys.maintenance || !sys.looperRegistration} />
                <FormButton onClick={prevStep} label="Previous Step" />
              </>
            );
        default: 
          // do nothing
    }
}

export default LooperSignupMultiStep;
