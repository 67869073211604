import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import * as bona from '../../../config/constants';
import {useState, useEffect} from 'react';

function DatePicker(props) {
    const [month, setMonth] = useState("");
    const [day, setDay] = useState("");
    const [year, setYear] = useState("");
    const [displayMonth, setDisplayMonth] = useState("");
    const [displayDay, setDisplayDay] = useState("");
    const [displayYear, setDisplayYear] = useState("");

    const monthList = bona.dates.months.map((option) =>
        <Dropdown.Item eventKey={option.db} key={option.db} onClick={() => setDisplayMonth(option.display)}>{option.display}</Dropdown.Item>
    );

    const dayList = bona.dates.days.map((option) =>
        <Dropdown.Item eventKey={option.db} key={option.db} onClick={() => setDisplayDay(option.display)}>{option.display}</Dropdown.Item>
    );

    const yearList = bona.dates.years.map((option) =>
        <Dropdown.Item eventKey={option.db} key={option.db} onClick={() => setDisplayYear(option.display)}>{option.display}</Dropdown.Item>
    );

    useEffect(() => {
        if (month && day && year) {
            const combo = `${month}/${day}/${year}`
            props.setDate(combo);
        }
        else {
            props.setDate("");
        }
    }, [month, day, year, props]);

    return (
        <>
            <Form.Group className="d-flex flex-column mb-3">
                <Form.Label className="px-1 fw-bold">{props.label}</Form.Label>
                <Form.Group className="d-inline-flex gap-1">
                    <Dropdown onSelect={(e) => setMonth(e)} value={month}>
                        <Dropdown.Toggle variant="secondary">{displayMonth || "Month..."}</Dropdown.Toggle>
                        <Dropdown.Menu variant="dark" className="overflow-scroll" style={{maxHeight: "200px"}}>{monthList}</Dropdown.Menu>
                    </Dropdown>
                    <Dropdown onSelect={(e) => setDay(e)}>
                        <Dropdown.Toggle variant="secondary">{displayDay || "Day..."}</Dropdown.Toggle>
                        <Dropdown.Menu variant="dark" className="overflow-scroll" style={{maxHeight: "200px"}}>{dayList}</Dropdown.Menu>
                    </Dropdown>
                    <Dropdown onSelect={(e) => setYear(e)}>
                        <Dropdown.Toggle variant="secondary">{displayYear || "Year..."}</Dropdown.Toggle>
                        <Dropdown.Menu variant="dark" className="overflow-scroll" style={{maxHeight: "200px"}}>{yearList}</Dropdown.Menu>
                    </Dropdown>
                </Form.Group>
            </Form.Group>
        </>
    )
}

export default DatePicker;