import AdminNav from './AdminNav';
import DefaultNav from './DefaultNav';
import LooperNav from './LooperNav';
import PatronNav from './PatronNav';

function DynamicNav() {
    const loggedIn = false;
    const priviledge = 1;
    if (!loggedIn) {
        return (
            <>
                <DefaultNav />
            </>
        );
    }
    switch (priviledge) {
        case 1:
            return (
                <>
                    <PatronNav />
                </>
            );
        case 2:
            return (
                <>
                    <LooperNav />
                </>
            );
        case 3:
            return (
                <>
                    <DefaultNav />
                </>
            );
        case 4:
        case 5:
        case 6:
            return (
                <>
                    <AdminNav />
                </>
            );
        default:
            return (
                <>
                    <DefaultNav />
                </>
            );
    }
}

export default DynamicNav;