import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {HelmetProvider} from 'react-helmet-async';
import Home from './pages/Home';
import Exclusions from './pages/Exclusions';
import Help from './pages/Help';
import Faq from './pages/Faq';
import Signup from './pages/Signup';
import Patrons from './pages/Patrons';
import Loopers from './pages/Loopers';
import Login from './pages/Login';
import NoMatch from './pages/NoMatch';
import About from './pages/About';
import SubmitAJob from './pages/SubmitAJob';
import JobFeed from './pages/JobFeed';
import PatronDash from './pages/PatronDash';
import LooperDash from './pages/LooperDash';
import PatronOnb from './pages/Onboarding';

function App() {
  return (
    <HelmetProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/exclusions" element={<Exclusions />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/help" element={<Help />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/patrons" element={<Patrons />} />
          <Route path="/loopers" element={<Loopers />} />
          <Route path="/home" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/about" element={<About />} />
          <Route path="/submitajob" element={<SubmitAJob />} />
          <Route path='/jobfeed' element={<JobFeed />} />
          <Route path="/patrons/dash" element={<PatronDash />} />
          <Route path="/loopers/dash" element={<LooperDash />} />
          <Route path="/patrons/onboarding" element={<PatronOnb />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

export default App;
