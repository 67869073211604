import React from "react";
import '../css/styles.css';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';
import CommonIntro from '../components/intros/CommonIntro';
import DynamicNav from '../components/nav';
import LargeFooter from '../components/footer/LargeFooter';
import * as bona from '../config/constants';

function Exclusions() {
  const exclusionItems = bona.exclusions.exclusionsList.map((exclusionItem, index) =>
    <ListGroup.Item key={index}>{exclusionItem}</ListGroup.Item>
  );
  
  return (
    <>
      <Container fluid as="main" className="bg-dark">
        <DynamicNav />
        <Container fluid className="bg-dark">
          <Container fluid className="bg-dark">
            <CommonIntro title={bona.exclusions.title} subheading={bona.exclusions.subheading} />
          </Container>
          <Container className="mt-2 py-4 bg-dark">
            <Card>
              <ListGroup className="text-center">{exclusionItems}</ListGroup>
            </Card>
          </Container>
        </Container>
        <LargeFooter />
      </Container>
     
    </>
  );
}

export default Exclusions;