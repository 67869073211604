import Container from 'react-bootstrap/Container';
import DynamicNav from '../components/nav';
import CommonIntro from '../components/intros/CommonIntro';
import LargeFooter from '../components/footer/LargeFooter';
import useDocumentTitle from '../functions/useDocumentTitle';
import PastJobs from '../components/feeds/pastJobs';
import CurrentJobs from '../components/feeds/currentJobs';
import Resources from '../components/feeds/resources'
import Accordion from 'react-bootstrap/Accordion';
import * as bona from '../config/constants';

function LooperDash () {
    useDocumentTitle('Dashboard | Bonaloop');
    const close = (jobID, jobDate) => {
        return true;
    }

    const withdraw = (jobID, jobDate) => {
        return true;
    }

    return (
        <>
            <Container fluid as="main" className="bg-dark">
                <DynamicNav />
                <Container fluid className="bg-dark">
                <CommonIntro title={bona.looperDash.title} subheading={bona.looperDash.subheading} />
                </Container>
                <Container className="bg-dark my-4">
                    <Accordion flush>
                        <PastJobs assetID={2488} assetType="Looper" filter=">= 9" />
                        <CurrentJobs assetID={2488} assetType="Looper" filter="< 9" close={close} withdraw={withdraw} />
                        <Resources assetType="loopers" />
                    </Accordion>
                </Container>
                <LargeFooter />
            </Container>
        </>
    );
}

export default LooperDash;