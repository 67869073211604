import React from 'react';
import '../css/Login.css';
import DynamicNav from '../components/nav';
import StrippedFooter from '../components/footer/StrippedFooter';
import useDocumentTitle from "../functions/useDocumentTitle";
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Inf from '../assets/images/Bonaloop-web-header-logo.png';
import Button from 'react-bootstrap/Button';
import {useState} from 'react';
import * as bona from '../config/constants';

function Login() {
  useDocumentTitle('Login | Bonaloop');
  
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  let handleSubmit = async (e) => {
    e.preventDefault();
    console.log(`Username: ${username} and Password: ${password}`);
    
    try {
      let res = await fetch(`${bona.links.api}/auth`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username: username,
          pwd: password
        }),
      });
      let resJson = await res.json();
      if (res.status === 200) {
        setUsername("");
        setPassword("");
        setMessage("User logged in successfully");
        console.log(resJson);
      } else {
        console.log(resJson);
        setMessage("Something went wrong");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <DynamicNav />
      <Container fluid as="main" className="bg-dark">
        <Card className="bg-light px-3 py-4 form-signin m-auto Card-Space">
          <Form onSubmit={handleSubmit}>
            <Image src={Inf} alt="Bonaloop Logo" width="50" height="50" className="mx-auto d-block mb-4" />
            <Form.Label as="h1" className="h3 mb-5 fw-formal text-center">Please Sign In</Form.Label>
            <Form.Label as="h5" className="h5 my-2 text-muted text-center">{message}</Form.Label>
            <Form.Group className="mb-3">
              <Form.Label className="px-1 fw-bold">Enter Your Email</Form.Label>
              <Form.Control type="email" placeholder="Enter your Email" value={username} onChange={(e) => setUsername(e.target.value)}/>
              <Form.Text className="text-muted">We'll never share your email with anyone else.</Form.Text>
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label className="px-1 fw-bold">Enter your Password</Form.Label>
              <Form.Control type="password" placeholder="Enter password" value={password} onChange={(e) => setPassword(e.target.value)}/>
            </Form.Group>
            <Form.Group className="mb-5">
              <Form.Check type="checkbox" label="Remember Me" />
            </Form.Group>
            <Button className="w-100 mb-2 btn btn-lg btn-primary" type="submit">Sign in</Button>
          </Form>
        </Card>
      </Container>
      <StrippedFooter />
      
    </>
  );
}

export default Login;