import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import * as bona from '../../config/constants';


function ResourceCard (props) {
    return (
        <Card key={props.resourceID} className="m-1 mt-2" style={{width: '34em' }} border="light">
            <Card.Body className="m-0">
                <Button variant="secondary" className="float-end" href={`${bona.links.domain}${props.location}`} target="_blank" rel="noopener noreferrer">View</Button>
                <Card.Title className="pt-1 px-2 text-center">{`${props.name}`}</Card.Title>
            </Card.Body>
        </Card>
    );
}

export default ResourceCard;