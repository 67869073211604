import FormButton from './components/formButton';
import Card from 'react-bootstrap/Card';
import * as bona from '../../config/constants';
import * as sys from '../../config/variables';

function SignupPickIdentity (props) {
    return (
        <>
            <Card border="light">
                <Card.Body className="lead text-black">
                    <Card.Title className="display-5 pfw-bold fw-bolder text-center">{bona.signupPicker.loopers.header}</Card.Title>
                    <Card.Text>{bona.signupPicker.loopers.desc}</Card.Text>
                </Card.Body>
            </Card>
            <FormButton label="Signup as Looper?" onClick={() => props.setUserType("Looper")} disabled={sys.maintenance || !sys.looperRegistration} />
            <Card border="light" className="mt-5">
                <Card.Body className="lead text-black">
                    <Card.Title className="display-5 pfw-bold fw-bolder text-center">{bona.signupPicker.patrons.header}</Card.Title>
                    <Card.Text>{bona.signupPicker.patrons.desc}</Card.Text>
                </Card.Body>
            </Card>
            <FormButton label="Signup as Patron?" onClick={() => props.setUserType("Patron")} disabled={sys.maintenance || !sys.patronRegistration} />
        </>
    );
}

export default SignupPickIdentity;