export const closed = true;
export const maintenance = true;
export const security = true;
export const looperRegistration = true;
export const patronRegistration = false;
export const jobSubmission = false;
export const onboarding = true;
export const jobFeed = false;
export const googleAPIKey = "AIzaSyAqiF1KH-C7twVWR5AzALvXfAoEH7RUF3w";
export const emailSending = false;
export const smsSending = false;