import React from 'react';
import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';
import DynamicNav from '../components/nav';
import CommonIntro from '../components/intros/CommonIntro';
import LargeFooter from '../components/footer/LargeFooter';
import useDocumentTitle from '../functions/useDocumentTitle';
import * as bona from '../config/constants';

function Faq () {
  const faqItems = bona.faq.faqna.map((faqItem, index) =>
    <Accordion.Item key={index} eventKey={faqItem.question}>
      <Accordion.Header>{<b>{faqItem.question}</b>}</Accordion.Header>
      <Accordion.Body dangerouslySetInnerHTML={{__html:faqItem.answer}}></Accordion.Body>
    </Accordion.Item>
  );
  useDocumentTitle('FAQs | Bonaloop');
  return (
    <>
      <Container fluid as="main" className="bg-dark">
        <DynamicNav />
        <Container fluid className="bg-dark">
          <CommonIntro title={bona.faq.title} subheading={bona.faq.subheading} />
        </Container>
        <Container className="bg-dark my-4">
          <Accordion flush >{faqItems}</Accordion>
        </Container>
        <LargeFooter />
      </Container>
    </>
  );
}

export default Faq;