import React, {useEffect, useState} from 'react';
import useDocumentTitle from '../functions/useDocumentTitle';
import '../css/Login.css';
import DynamicNav from '../components/nav';
import StrippedFooter from '../components/footer/StrippedFooter';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import FormHeader from '../components/forms/components/formHeader';
import SubmitAJobMultiStep from '../components/forms/SubmitAJobMultiStep';

function SubmitAJob (props) {
    useDocumentTitle('Submit A Job | Bonaloop');
    const [message, setMessage] = useState("");
    const [values, setValues] = useState({});

    useEffect(() => {
        //console.log(values);
    }, [values]);
    
    return (
        <>
            <DynamicNav />
            <Container fluid as="main" className="bg-dark">
                <Card className="bg-light px-3 py-4 form-signin m-auto signup-width">
                    <Form>
                        <FormHeader header="Submit a Job" message={message} />
                        <SubmitAJobMultiStep setMessage={setMessage} setValues={setValues} values={values} />
                    </Form>
                </Card>
            </Container>
            <StrippedFooter />
        </>
    );
}

export default SubmitAJob;