import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import LargeFooter from '../components/footer/LargeFooter';
import DynamicNav from '../components/nav';
import jonaldo from '../assets/images/team/Jonaldo.png';
import brendan from '../assets/images/team/Brendan.png';

function About() {
    return (
        <>
            {/*<Helmet>
                <title>About Us | Bonaloop</title>
                <meta name="description" content="" />
            </Helmet>*/}
            <Container fluid as="main" className="bg-dark">
                <DynamicNav />
                <Container fluid className="bg-dark my-4">
                    <Card bg="danger" text="dark" border="dark">
                        <Card.Body>
                            <Card.Title as="h1" className="mb-3 py-4">About Us</Card.Title>
                            <Card className="my-4" bg="dark" text="light" border="danger">
                                <Card.Header as="h5" className="bg-light text-black">Our Mission</Card.Header>
                                <Card.Body>
                                    <Card.Text className="ms-3">Bonaloop aims to connect students with their local communities by serving as a resource for per-diem hourly work &amp; networking opportunities</Card.Text>
                                </Card.Body>
                            </Card>
                            <Card className="my-4" bg="dark" text="light" border="danger">
                                <Card.Header as="h5" className="bg-light text-black">Our Story</Card.Header>
                                <Card.Body>
                                    <Card.Title>2014</Card.Title>
                                    <Card.Text className="ms-3">Bonaloop LLC, commonly just Bonaloop, was founded in 2014 out of Beverly, MA by two college students that were looking to make some money in their spare time around their busy schedules. For lots of students, busy schedules and a need for flexible work arrangements is a must and for some, the only option! That's why one day, those two college students teamed up with a long time customer and legitimized the business. After that, there was only one more thing left to do: spread the word and grow! Soon, college students from all over the North Shore (in Massachusetts) heard the calling and were signing up to help other members of the community with their to-do lists and getting paid for it!</Card.Text>
                                </Card.Body>
                                <Card.Body>
                                    <Card.Title>2019</Card.Title>
                                    <Card.Text className="ms-3">In 2019, the founding owners started deliberating shutting down the business amidst that start of the pandemic and forced closures.  When some of the current students heard about this, we teamed up and discussed a plan of attack for the business in hopes of keeping it alive.  We believed the business had too much potential and did too much good for all parties that we could not let it go without giving it our all.  Following that, we were offered to take ownership of the business and we temporarily closed to make improvements and respect the wishes of the government and the CDC.  When allowed, we reopened with new systems and new owners ready to return us to our full glory and with dreams of making the business bigger than it ever was.</Card.Text>
                                </Card.Body>
                                <Card.Body>
                                    <Card.Title>Today</Card.Title>
                                    <Card.Text className="ms-3">Today, most restrictions have been lifted nation-wide, stimulus injections distributed to those who qualified, and safety measures implemented across businesses everywhere.  Additionally, Bonaloop is still here and fully open.  The business has not returned to its former glory as we will always have a 100% turnover every 4 years and were not able to source for more students while they were unable to attend school but we are continuously giving it our all to return to it and maximize our potential however we can.  Eventually, we plan to grow the business to additional communities all over the country!</Card.Text>
                                </Card.Body>
                            </Card>
                            <Card className="my-4 pb-2" bg="dark" text="light" border="danger">
                                <Card.Header as="h5" className="bg-light text-black">Our Team</Card.Header>
                                <Card.Body className="d-inline-flex flex-wrap justify-content-around gap-1 text-center">
                                    <Card className="flex-fill p-3" bg="dark" border="light">
                                        <Card.Title>Jonaldo Alix<br/><span className="text-danger">Head of Technology</span></Card.Title>
                                        <Card.Subtitle className="text-muted mb-1">I handle tech issues!</Card.Subtitle>
                                        <Card.Img src={jonaldo} alt="Jonaldo Alix" style={{ width: '90px' }} className="mx-auto"/>
                                        <Card.Text>
                                            <Card.Link href="tel:8888856045,,1002">Direct Extension: 1002</Card.Link><br/>
                                            <Card.Link href="tel:8888856045,,3004">Team Extension: 3004</Card.Link><br/>
                                            <Card.Link href="mailto: jonaldo@bonaloop.com">Direct Email</Card.Link><br/>
                                            <Card.Link href="mailto: it@bonaloop.com">Team Email</Card.Link><br/>
                                            <span as="h6" className="text-muted mt-2">Here is a quote</span>
                                        </Card.Text>
                                    </Card>
                                    <Card className="flex-fill p-3" bg="dark" border="light">
                                        <Card.Title>Alec DeBenedictis<br/><span className="text-danger">Head of Marketing</span></Card.Title>
                                        <Card.Subtitle className="text-muted mb-1">I handle all marketing!</Card.Subtitle>
                                        <Card.Img src={jonaldo} alt="Jonaldo Alix" style={{ width: '90px' }} className="mx-auto"/>
                                        <Card.Text>
                                            <Card.Link href="tel:8888856045,,1002">Direct Extension: 1002</Card.Link><br/>
                                            <Card.Link href="tel:8888856045,,3004">Team Extension: 3004</Card.Link><br/>
                                            <Card.Link href="mailto: jonaldo@bonaloop.com">Direct Email</Card.Link><br/>
                                            <Card.Link href="mailto: it@bonaloop.com">Team Email</Card.Link><br/>
                                            <span as="h6" className="text-muted mt-2">Here is a quote</span>
                                        </Card.Text>
                                    </Card>
                                    <Card className="flex-fill p-3" bg="dark" border="light">
                                        <Card.Title>Brendan Chan<br/><span className="text-danger">Head of Operations</span></Card.Title>
                                        <Card.Subtitle className="text-muted mb-1">I handle logistical issues!</Card.Subtitle>
                                        <Card.Img src={brendan} alt="Brendan Chan" style={{ width: '90px' }} className="mx-auto"/>
                                        <Card.Text>
                                            <Card.Link href="tel:8888856045,,1001">Direct Extension: 1001</Card.Link><br/>
                                            <Card.Link href="tel:8888856045,,3001">Team Extension: 3001</Card.Link><br/>
                                            <Card.Link href="mailto: chan@bonaloop.com">Direct Email</Card.Link><br/>
                                            <Card.Link href="mailto: operations@bonaloop.com">Team Email</Card.Link><br/>
                                            <span as="h6" className="text-muted mt-2">Here is a quote</span>
                                        </Card.Text>
                                    </Card>
                                </Card.Body>
                            </Card>
                        </Card.Body>
                    </Card>
                </Container>
                <LargeFooter />
            </Container>
        </>
    );
}

export default About;