import React, {useEffect, useState} from 'react';
import useDocumentTitle from '../functions/useDocumentTitle';
import '../css/Login.css';
import DynamicNav from '../components/nav';
import StrippedFooter from '../components/footer/StrippedFooter';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import FormHeader from '../components/forms/components/formHeader';
import SignupPickIdentity from '../components/forms/SignupPickIdentity';
import LooperSignupMultiStep from '../components/forms/LooperSignupMultiStep';
import PatronSignupMultiStep from '../components/forms/PatronSignupMultiStep';

function Signup () {
    useDocumentTitle('Sign Up! | Bonaloop');
    const [userType, setUserType] = useState("");
    const [message, setMessage] = useState("");
    const [values, setValues] = useState({});

    useEffect(() => {
      //console.log(values);
    }, [values]);
    
    return (
      <>
        <DynamicNav />
        <Container fluid as="main" className="bg-dark">
          <Card className="bg-light px-3 py-4 form-signin m-auto signup-width">
            <Form>
              <FormHeader header="Sign Up!" message={message} />
              {userType === "" && <SignupPickIdentity setUserType={setUserType} />}
              {userType === "Looper" && <LooperSignupMultiStep setMessage={setMessage} setValues={setValues} values={values} />}
              {userType === "Patron" && <PatronSignupMultiStep setMessage={setMessage} setValues={setValues} values={values} />}
            </Form>
          </Card>
        </Container>
        <StrippedFooter />
      </>
    );  
}

export default Signup;
