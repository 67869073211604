import hookFunctions from '../../functions/jobSubmissionFunctions';
import FormInput from './components/formInput';
import FormPhone from './components/formPhone';
import FormAddress from './components/formAddress';
import FormButton from './components/formButton';
import FormAddressTypePicker from './components/formAddressTypePicker';
import FormPhoneTypePicker from './components/formPhoneTypePicker';
import FormOutdoors from './components/formOutdoors';
import FormLooperCount from './components/formLooperCount';
import FormTruck from './components/formTruck';
import FormNumber from './components/formNumber';
import FormJobSubmitReview from './components/formJobSubmitReview';
import {useState} from 'react';
import FormJobTypePicker from './components/formJobTypePicker';
import FormAvailability from './components/formAvailability';
import * as sys from '../../config/variables';

function SubmitAJobMultiStep(props) {
    const [step, setStep] = useState(1);
    const [validatedEntries, setValidatedEntries] = useState({});
    
    // Step 1 Form Inputs
    const [jobTypeID, setJobTypeID] = useState("");
    const [jobTypeName, setJobTypeName] = useState("");
    const [jobDescription, setJobDescription] = useState("");
    const [uponArrival, setUponArrival] = useState("");
    
    // Step 2 Form Inputs
    const [dates, setDates] = useState([]);
    
    // Step 3 Form Inputs
    const [preferredNumOfLoopers, setPreferredNumOfLoopers] = useState(1);
    const [outdoors, setOutdoors] = useState("");
    const [truck, setTruck] = useState("");
    const [incentive, setIncentive] = useState("");
    
    // Step 4 Form Inputs
    const [jobPhoneTypeID, setJobPhoneTypeID] = useState("");
    const [jobPhoneTypeName, setJobPhoneTypeName] = useState("");
    const [jobPhone, setJobPhone] = useState("");
    const [jobAddressTypeID, setJobAddressTypeID] = useState("");
    const [jobAddressTypeName, setJobAddressTypeName] = useState("");
    const [jobAddress, setJobAddress] = useState("");
    
    // go back to previous step
    let prevStep = () => {
        if (step - 1 >= 1) {
            props.setMessage("");  
            setStep(step - 1);
        }
    }

    // proceed to the next step
    let nextStep = () => {

        if (step + 1 <= 5) {
            props.setMessage("");
            setStep(step + 1);
        }  
    }

    // for continue event listener
    let Continue = e => {
        e.preventDefault();
        switch (step) {
          case 1:
            if (hookFunctions.validateJobSubmission({
                jobTypeID: jobTypeID,
                jobDescription: jobDescription,
            }, props.setMessage, step)) {
              nextStep();
            }
            break;
          case 2:
            if (hookFunctions.validateJobSubmission({
                dates: dates
            }, props.setMessage, step)) {
              nextStep();
            }
            break;
          case 3:
            if (hookFunctions.validateJobSubmission({
                outdoors: outdoors,
                truck: truck,
                preferredNumOfLoopers: preferredNumOfLoopers,
            }, props.setMessage, step)) {
              nextStep();
            }
            break;
          case 4:
            if (hookFunctions.validateJobSubmission({
                jobPhoneTypeID: jobPhoneTypeID,
                jobPhone: jobPhone,
                jobAddressTypeID: jobAddressTypeID,
                jobAddress: jobAddress.label
            }, props.setMessage, step)) {
              setValidatedEntries({
                jobTypeID: jobTypeID,
                jobTypeName: jobTypeName,
                jobDescription: jobDescription,
                uponArrival: uponArrival,
                dates: dates,
                preferredNumOfLoopers: preferredNumOfLoopers,
                outdoors: outdoors,
                truck: truck,
                incentive: incentive,
                jobPhoneTypeName: jobPhoneTypeName,
                jobPhoneTypeID: jobPhoneTypeID,
                jobAddressTypeName: jobAddressTypeName,
                jobAddressTypeID: jobAddressTypeID,
                jobPhone: jobPhone,
                jobAddress: jobAddress.label
              });
              nextStep();
            }
            break;
          default:
            break;
        }
    };

    let validateAndSubmit = (e) => {
      e.preventDefault();
      const validation = hookFunctions.validateJobSubmission(validatedEntries, props.setMessage, step);
      if (validation === true) {
        const formattedNumber = jobPhone;
        const cleanedPhoneNumber = formattedNumber.replace(/[^\d]/g, '');
        props.setValues({ 
            jobTypeID: jobTypeID,
            jobDescription: jobDescription,
            uponArrival: uponArrival,
            dates: dates,
            preferredNumOfLoopers: preferredNumOfLoopers,
            outdoors: outdoors,
            truck: truck,
            incentive: incentive,
            jobPhoneTypeID: jobPhoneTypeID,
            jobAddressTypeID: jobAddressTypeID,
            jobPhone: cleanedPhoneNumber,
            jobAddress: jobAddress.label
        });
      }
    }

    switch(step) {
        case 1: // Job High Level Details  
          return (
            <>
              <FormJobTypePicker label="What type of job is it?" instructions="Select type of job..." setJobTypeID={setJobTypeID} value={jobTypeID} showMe={jobTypeName} setJobTypeName={setJobTypeName} />
              <FormInput label="Job Description" placeholder="Enter a brief description of the job here..." value={jobDescription} onChange={(e) => setJobDescription(e.target.value)} />
              <FormInput label="Instruction to Follow Upon Arrival" placeholder="Optional extra directions or instruction goes here..." value={uponArrival} onChange={(e) => setUponArrival(e.target.value)} />
              <FormButton onClick={Continue} label="Continue" disabled={sys.maintenance || !sys.jobSubmission} />
            </>
          );
        case 2: // Job Availability Info
          return (
            <>
              <FormAvailability dates={dates} setDates={setDates} nextStep={nextStep} prevStep={prevStep} setMessage={props.setMessage} />
            </>
          );
        case 3: // Job Low Level Details
          return (
              <>
                <FormLooperCount label="How many Loopers do you require?" subText="The job will require this number of Loopers to commit to the job in order for the job to take place." value={preferredNumOfLoopers} onChange={setPreferredNumOfLoopers} />
                <FormOutdoors heading="Is this job outdoors?" groupName="outdoors" value={outdoors} setInput={setOutdoors} subText="" />
                <FormTruck heading="Does the job require a Looper vehicle?" groupName="truck" value={truck} setInput={setTruck} subText="There will be an additional flat charge for the use of Looper vehicles." />
                <FormNumber label="Would you like to incentivize Loopers to pick up this job with an additional monetary bonus?" placeholder="Enter Bonus Here... (optional)" subText="This bonus is not a tip for service.  It is an incentive for Loopers to pick up this job and is paid upon completion of the job in addition to hourly wages and any tip for service you choose to include.  This figure would be split amongst all Loopers for the job." value={incentive} onChange={(e) => setIncentive(e.target.value)} />
                <FormButton onClick={Continue} label="Continue" disabled={sys.maintenance || !sys.jobSubmission} />
                <FormButton onClick={prevStep} label="Previous Step" />
              </>
          );
        case 4: // Contact and Location Info
          return (
            <>
              <FormAddressTypePicker label="What is the type of Address?" instructions="Select the address type..." value={jobAddressTypeID} setAddressTypeID={setJobAddressTypeID} setAddressTypeName={setJobAddressTypeName} showMe={jobAddressTypeName} />
              <FormAddress label="Permanent Address" placeholder="Start to enter your address here..." value={jobAddress} onChange={setJobAddress} subText="Enter your address above until you see your address show up from the list of possible choices and then physically click it to designate it as your permanent address." />
              <FormPhoneTypePicker label="What is the type of Phone?" instructions="Select type of phone..." setPhoneTypeID={setJobPhoneTypeID} value={jobPhoneTypeID} showMe={jobPhoneTypeName} setPhoneTypeName={setJobPhoneTypeName}/>
              <FormPhone header="Personal Phone Number" subText="Enter your 10 digit phone number here.  We prefer you use your mobile number as we will use it to contact you during jobs and will send you occassional notifications via SMS that may be time-sensitive." value={jobPhone} onChange={(e) => hookFunctions.validatePhone(e, setJobPhone)} />
              <FormButton onClick={Continue} label="Continue" disabled={sys.maintenance || !sys.jobSubmission} />
              <FormButton onClick={prevStep} label="Previous Step" />
            </>
          );
        case 5: // Submit and Confirmation
            return (
              <>
                <FormJobSubmitReview values={validatedEntries} />
                <FormButton onClick={validateAndSubmit} label="Confirm and Continue" disabled={sys.maintenance || !sys.jobSubmission} />
                <FormButton onClick={prevStep} label="Previous Step" />
              </>
            );
        default: 
          // do nothing
    }
}

export default SubmitAJobMultiStep;
