import Logo from '../../assets/images/bona-web-banner.png';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import {useLocation} from 'react-router-dom';
import * as sys from '../../config/variables';
import * as bona from '../../config/constants';

function DefaultNav() {
    const location = useLocation();
    // console.log('hash', location.hash);
    // console.log('pathname', location.pathname);
    // console.log('search', location.search);

    return (
        <>
            <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
                <Container>
                    <Navbar.Brand href={bona.page.mainHome}>
                        <img
                            alt="Bonaloop Logo"
                            src={Logo}
                            width="190"
                            height="70"
                            className="d-inline-block align-top"
                        />{' '}
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            {location.pathname === bona.page.looperHome && <Nav.Link href={bona.page.looperHome}>Looper Home</Nav.Link>}
                            {location.pathname === bona.page.patronHome && <Nav.Link href={bona.page.patronHome}>Patron Home</Nav.Link>}
                            {location.pathname !== bona.page.patronHome && location.pathname !== bona.page.looperHome && <Nav.Link href={bona.page.mainHome}>Home</Nav.Link>}
                            {location.pathname === bona.page.looperHome && <Nav.Link href="#whatis">What's a Looper?</Nav.Link>}
                            {(location.pathname === bona.page.looperHome || location.pathname === bona.page.patronHome) && <Nav.Link href="#howitworks">How's it work?</Nav.Link>}
                            {(location.pathname === bona.page.looperHome || location.pathname === bona.page.patronHome) && <Nav.Link href="#services">Services</Nav.Link>}
                            {location.pathname === bona.page.looperHome && <Nav.Link href="#benefits">Benefits</Nav.Link>}
                            {location.pathname === bona.page.patronHome && <Nav.Link href="#makeadiff">Make A Difference</Nav.Link>}
                            {(location.pathname === bona.page.looperHome || location.pathname === bona.page.patronHome) && <Nav.Link href='#cta'>Ready?</Nav.Link>}
                            {location.pathname !== bona.page.patronHome && location.pathname !== bona.page.looperHome && 
                            <NavDropdown title="Looper Options" id="looperoptions">
                                <NavDropdown.Item href={bona.page.looperHome}>Looper Home</NavDropdown.Item>
                                <NavDropdown.Item href={`${bona.page.looperHome}#whatis`}>What's a Looper?</NavDropdown.Item>
                                <NavDropdown.Item href={`${bona.page.looperHome}#howitworks`}>How's it work?</NavDropdown.Item>
                                <NavDropdown.Item href={`${bona.page.looperHome}#services`}>Services</NavDropdown.Item>
                                <NavDropdown.Item href={`${bona.page.looperHome}#benefits`}>Benefits</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href={`${bona.page.looperHome}#cta`}>Ready?</NavDropdown.Item>
                            </NavDropdown>}
                            {location.pathname !== bona.page.patronHome && location.pathname !== bona.page.looperHome &&
                            <NavDropdown title="Patron Options" id="patronoptions">
                                <NavDropdown.Item href={bona.page.patronHome}>Patron Home</NavDropdown.Item>
                                <NavDropdown.Item href={`${bona.page.patronHome}#howitworks`}>How's it work?</NavDropdown.Item>
                                <NavDropdown.Item href={`${bona.page.patronHome}services`}>Services</NavDropdown.Item>
                                <NavDropdown.Item href={`${bona.page.patronHome}#makeadiff`}>Make a Difference</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href={`${bona.page.patronHome}#cta`}>Ready?</NavDropdown.Item>
                            </NavDropdown>}
                        </Nav>
                        <Nav className="me-auto">
                            <Nav.Link href={bona.page.about}>Our History</Nav.Link>
                            <Nav.Link href={bona.page.faqs}>FAQ</Nav.Link>
                            <Nav.Link href={bona.page.exclusions}>Exclusions</Nav.Link>
                            <Nav.Link href={bona.page.help} disabled={sys.closed}>Help Center</Nav.Link>
                        </Nav>
                        <Nav>
                            <Nav.Link href={bona.page.login} disabled={sys.closed || sys.maintenance}>Login</Nav.Link>
                            <Nav.Link href={bona.page.signup} disabled={sys.closed || sys.maintenance || (!sys.patronRegistration && !sys.looperRegistration) === false}>Sign Up</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}

export default DefaultNav;