import ResourceCard from './resourceCard';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import * as bona from '../../config/constants';
import * as sys from '../../config/variables';
import {useState, useEffect} from 'react';

function Resources (props) {
    const [resources, setResources] = useState([]);

    useEffect(() => {
        const fetchResources = async () => {
            const response = await fetch(`${bona.links.currAPI}/resources/${props.assetType}`);
            const resourcesIn = await response.json();
            setResources(resourcesIn);
        };

        if (!sys.maintenance) {
            fetchResources();
        }
    }, [props]);

    const resourceList = resources.map(key => {
        return (
            <ResourceCard key={key.resourceID} resourceID={key.resourceID} name={key.name} type={key.type} location={key.location} />
        );
    });

    return (
        <Accordion.Item eventKey="resources" key="resources">
            <Accordion.Header><b>Resources</b></Accordion.Header>
            <Accordion.Body className="d-flex flex-row flex-wrap justify-content-between bg-dark p-1 mt-1">
                {resources.length > 0 && resourceList}
                <Card key={props.resourceID} className="m-1 mt-2 w-100" border="light">
                    <Card.Body className="m-0 px-2 py-1">
                        <Card.Title className="pt-1">Looking for something?</Card.Title>
                        <Card.Subtitle className="pt-1 pb-3"><i>There are no resources left to display...</i></Card.Subtitle>
                        <Card.Text className="m-0 py-1 text-center">What would you like to do?</Card.Text>
                    </Card.Body>
                    <Card.Footer className="m-0 px-2 py-1 d-flex flex-row flex-wrap justify-content-around">
                        <Button variant="danger" disabled={!sys.onboarding || sys.maintenance} href={bona.page.onboarding}>Visit Onboarding Central</Button>
                        <Button variant="danger" disabled={sys.closed} href={bona.page.help}>Get Help</Button>
                    </Card.Footer>
                </Card>
            </Accordion.Body>
        </Accordion.Item>
    );
}

export default Resources;