import '../css/Home.css';
import Logo from '../assets/images/bona-web-banner.png';
import useDocumentTitle from "../functions/useDocumentTitle";
import BigIntro from '../components/intros/BigIntro';
import StrippedFooter from '../components/footer/StrippedFooter';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import * as bona from '../config/constants';

function Home() {
    useDocumentTitle('Welcome | Bonaloop');
    return (
        <>
            <Container fluid as="main" className="bg-dark">
                <Container fluid className="bg-dark">
                    <Container fluid className="bg-dark text-white px-1 py-2 text-center">
                        <Image src={Logo} alt="Bonaloop Banner" className="d-block mx-auto bona-logo" height="300" width="800" />
                        <Container fluid className="col-lg-6 mx-auto bg-dark py-3">
                            <BigIntro title={bona.home.title} text={bona.home.desc}/>
                            <Container className="d-grid gap-2 d-sm-flex justify-content-sm-center bg-dark py-3">
                                <Button bg="secondary" variant="secondary" href={bona.page.patronHome}>Visit Patron Site</Button>
                                <Button bg="secondary" variant="secondary" href={bona.page.looperHome}>Visit Looper Site</Button>
                                <Button bg="secondary" variant="secondary" href={bona.page.login}>Skip to Login Page</Button>
                            </Container>
                        </Container>
                    </Container>
                </Container>
                <StrippedFooter />
            </Container>
        </>
    );
}

export default Home;