import Form from 'react-bootstrap/Form';
import Inf from '../../../assets/images/Bonaloop-web-header-logo.png';
import Image from 'react-bootstrap/Image';

function FormHeader(props) {
    return (
        <>
            <Image src={Inf} alt="Bonaloop Logo" width="50" height="50" className="mx-auto d-block mb-4" />
            <Form.Label as="h1" className="h3 mb-4 fw-formal text-center">{props.header}</Form.Label>
            <Form.Label as="h5" className="h5 mb-5 text-muted text-center">{props.message}</Form.Label>
        </>
    );
}

export default FormHeader;