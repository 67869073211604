import FormDatePicker from './formDatePicker';
import FormTimePicker from './formTimePicker';
import FormButton from './formButton';
import FormAvailabilityDates from './formAvailabilityDates';
import Form from 'react-bootstrap/Form';
import ClusterButton from './formClusterButton';
import {useState} from 'react';
import * as sys from '../../../config/variables';

function FormAvailability (props) {
    const [dateType, setDateType] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [date, setDate] = useState("");
    
    let addDate = e => {
        e.preventDefault();
        if (startTime && endTime && date && dateType) {
            const dateObj = {
                index: props.dates.length,
                dateType: dateType,
                date: date,
                startTime: startTime,
                endTime: endTime
            }
            props.setDates(prevDates => ([...prevDates, dateObj]));
            props.setMessage("Date added successfully!");
            setDate("");
            setStartTime("");
            setEndTime("");
            setDateType(0);
        }
        else {
            props.setMessage("You must complete or cancel the availability slot.");
        }
    };

    let addDateAndContinue = e => {
        e.preventDefault();
        if (startTime && endTime && date && dateType) {
            const dateObj = {
                index: props.dates.length,
                dateType: dateType,
                date: date,
                startTime: startTime,
                endTime: endTime
            }
            props.setDates(prevDates => ([...prevDates, dateObj]));
            props.setMessage("");
            setDate("");
            setStartTime("");
            setEndTime("");
            setDateType(0);
            props.nextStep();
        }
        else {
            props.setMessage("You must complete or cancel the availability slot.");
        }
    };

    let specificCase = e => {
        e.preventDefault();
        props.setMessage("");
        setDateType(1);
    }

    let windowCase = e => {
        e.preventDefault();
        props.setMessage("");
        setDateType(2);
    }

    let cancelAddition = e => {
        e.preventDefault();
        props.setMessage("");
        setDateType(0);
    }

    switch (dateType) {
        case 1:
            return (
                <>
                    <Form.Group className="d-flex flex-row flex-wrap justify-content-between">
                        <FormDatePicker className="col-lg-4" label="Date of Availability" setDate={setDate} value={date} />
                        <FormTimePicker className="col-lg-4" label="Start Time of the Job" setTime={setStartTime} value={startTime} />
                        <FormTimePicker className="col-lg-4" label="End Time of the Job" setTime={setEndTime} value={endTime} />
                    </Form.Group>
                    <hr /><br />
                    <FormButton onClick={addDate} label="Save &amp; Add Another" disabled={sys.maintenance || !sys.jobSubmission} />
                    <FormButton onClick={addDateAndContinue} label="Save &amp; Continue" disabled={sys.maintenance || !sys.jobSubmission} />
                    <FormButton variant="secondary" onClick={cancelAddition} label="Cancel This Entry" />
                </>
            );
        case 2:
            return (
                <>
                    <Form.Group className="d-flex flex-row flex-wrap justify-content-between">
                        <FormDatePicker label="Date of Availability" setDate={setDate} value={date} />
                        <FormTimePicker label="Start of Availability" setTime={setStartTime} value={startTime} />
                        <FormTimePicker label="End of Availability" setTime={setEndTime} value={endTime} />
                    </Form.Group>
                    <hr /><br />
                    <FormButton onClick={addDate} label="Save &amp; Add Another" disabled={sys.maintenance || !sys.jobSubmission} />
                    <FormButton onClick={addDateAndContinue} label="Save & Continue" disabled={sys.maintenance || !sys.jobSubmission} />
                    <FormButton variant="secondary" onClick={cancelAddition} label="Cancel This Entry" />
                </>
            );
        default:
            return (
                <>
                    {props.dates.length > 0 && <FormAvailabilityDates dates={props.dates} setDates={props.setDates} />}
                    <hr /><br />
                    <Form.Group className="d-flex flex-row justify-content-center my-2">
                        <ClusterButton variant="outline-primary" size="lg" onClick={specificCase} label="Add Specific Time" disabled={sys.maintenance || !sys.jobSubmission} />
                        <ClusterButton size="lg" variant="outline-primary" onClick={windowCase} label="Add Window of Time" disabled={sys.maintenance || !sys.jobSubmission} />
                    </Form.Group>
                    <Form.Group className="d-flex flex-row justify-content-center my-2">
                        <ClusterButton size="lg" onClick={props.prevStep} label="Previous Step" />
                        <ClusterButton size="lg" onClick={props.nextStep} label="Continue" disabled={sys.maintenance || !sys.jobSubmission} />
                    </Form.Group>
                </>
            );
    }
}

export default FormAvailability;