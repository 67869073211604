import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import {useState} from 'react';

function FormGradYear(props) {
    const [years, setYears] = useState([]);
    
    const fetchYears = () => {
        const currentYear = new Date().getFullYear();
        const min = (currentYear - 6);
        const max = (currentYear + 6);
        const yearsArray = [];
        for (let i = min; i <= max; i++) {
            yearsArray.push(i);
        }
        setYears(yearsArray);
    };

    const ddoutput = years.map(year => {
        return (
            <Dropdown.Item key={year} eventKey={year} onClick={() => props.setGradYear(year)}>{`${year}`}</Dropdown.Item>
        );
    });

    return (
        <>
            <Card border="light" className="my-3" onClick={fetchYears}>
                <Form.Group className="mb-3">
                    <Form.Label className="px-1 fw-bold">{props.label}</Form.Label>
                    <Dropdown aria-label={props.label} onSelect={(e) => props.setGradYear(e)} value={props.value}>
                        <Dropdown.Toggle variant="secondary">{props.showMe || props.instructions}</Dropdown.Toggle>
                        <Dropdown.Menu variant="secondary" className="overflow-scroll" style={{maxHeight: "200px"}}>{ddoutput}</Dropdown.Menu>
                    </Dropdown>
                    <Form.Text className="text-muted">{`Selected Grad Year: ${props.showMe}`}</Form.Text>
                </Form.Group>
            </Card>
        </>
    );
}

export default FormGradYear;