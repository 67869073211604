import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import {useState} from 'react';
import * as bona from '../../../config/constants';

function TimePicker(props) {
    const [displayTime, setDisplayTime] = useState("");

    const optionList = bona.dates.times.map((option) =>
        <Dropdown.Item eventKey={option.db} key={option.db} onClick={() => setDisplayTime(option.display)}>{option.display}</Dropdown.Item>
    );

    return (
        <>
            <Form.Group className="mb-3 d-flex flex-column">
                <Form.Label className="px-1 fw-bold">{props.label}</Form.Label>
                <Dropdown onSelect={(e) => props.setTime(e)}>
                    <Dropdown.Toggle variant="secondary">{displayTime || "Time..."}</Dropdown.Toggle>
                    <Dropdown.Menu variant="dark" className="overflow-scroll" style={{maxHeight: "200px"}}>{optionList}</Dropdown.Menu>
                </Dropdown>
            </Form.Group>
        </>
    )
}

export default TimePicker;