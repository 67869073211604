import Container from 'react-bootstrap/Container';
import DynamicNav from '../components/nav';
import CommonIntro from '../components/intros/CommonIntro';
import LargeFooter from '../components/footer/LargeFooter';
import useDocumentTitle from '../functions/useDocumentTitle';
import Feed from '../components/feeds/jobFeed';
import * as bona from '../config/constants';

function JobFeed () {
    useDocumentTitle('Job Feed | Bonaloop');
    let apply = (jobID) => {
        return true;
    }

    return (
        <>
        <Container fluid as="main" className="bg-dark">
            <DynamicNav />
            <Container fluid className="bg-dark">
            <CommonIntro title={bona.jobfeed.title} subheading={bona.jobfeed.subheading} />
            </Container>
            <Container className="bg-dark my-4">
                <Feed apply={apply} />
            </Container>
            <LargeFooter />
        </Container>
        </>
    );
}

export default JobFeed;