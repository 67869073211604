import React, {useState} from 'react';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import Card from 'react-bootstrap/Card';
import * as bona from '../../../config/constants';
import * as sys from '../../../config/variables';

function FormSchoolPicker(props) {
    const [schools, setSchools] = useState([]);
    
    const fetchSchools = async () => {
        if (!sys.maintenance) {
            const response = await fetch(`${bona.links.currAPI}/schools/all`);
            const schoolsIn = await response.json();
            setSchools(schoolsIn);
        }
    };

    const ddoutput = schools.map(key => {
        return (
            <Dropdown.Item key={key.schoolID} eventKey={key.name} onClick={() => props.setSchoolID(key.schoolID)}>{`${key.name}`}</Dropdown.Item>
        );
    });

    return (
        <>
            <Card border="light" className="my-3" onClick={fetchSchools}>
                <Form.Group className="mb-3">
                    <Form.Label className="px-1 fw-bold">{props.label}</Form.Label>
                    <Dropdown aria-label={props.label} onSelect={(e) => props.setSchoolName(e)} value={props.value}>
                        <Dropdown.Toggle variant="secondary">{props.showMe || props.instructions}</Dropdown.Toggle>
                        <Dropdown.Menu variant="secondary" className="overflow-scroll" style={{maxHeight: "200px"}}>{ddoutput}</Dropdown.Menu>
                    </Dropdown>
                    <Form.Text className="text-muted">{`Selected School: ${props.showMe}`}</Form.Text>
                </Form.Group>
            </Card>
        </>
    );
}

export default FormSchoolPicker;