import Form from 'react-bootstrap/Form';
import GooglePlacesAutoComplete from 'react-google-places-autocomplete';
import * as sys from '../../../config/variables';

function FormAddress(props) {
    return (
        <>
            <Form.Group className="mb-3">
                <Form.Label className="px-1 fw-bold">{props.label}</Form.Label>
                <GooglePlacesAutoComplete apiKey={sys.googleAPIKey} onLoadFailed={(error) => alert(`You'll have to call us to get this done.  Address suggestion loading has failed.  Please call us at the number at the foot of the page.  Error: ${error}`)} selectProps={{value: props.value, onChange: props.onChange, placeholder: props.placeholder}} />
                <Form.Text className="text-muted">{props.subText}</Form.Text>
            </Form.Group>
        </>
    );
}

export default FormAddress;