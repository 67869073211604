import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

function FormAvailabilityDates(props) {
    const removeDate = (index) => {
        props.setDates(currentDates =>
            currentDates.filter(slot => {
                return slot.index !== index;
            }),
        );
    };

    const list = props.dates.map((slot) => {
        switch (slot.dateType) {
            case 1: 
                return (
                    <Card key={slot.index} className="col-4 mx-2" style={{ width: '16rem' }}>
                        <Card.Body>
                        <Card.Title>Specific Availability</Card.Title>
                        <ListGroup className="p-1 mt-2 mb-3 list-group-flush">
                            <ListGroup.Item><b>Date: </b>{slot.date}</ListGroup.Item>
                            <ListGroup.Item><b>Start Time: </b>{slot.startTime}</ListGroup.Item>
                            <ListGroup.Item><b>Approx. End: </b>{slot.endTime}</ListGroup.Item>
                        </ListGroup>
                        <Card.Text className="text-muted" style={{ fontSize: '13px' }}>
                            If Loopers pick up this job, they will show up at the start time to complete the job.  They are only committed to stay until the end time though they may finish early or offer to stay and finish the job beyond the end time if needed.
                        </Card.Text>
                        <Button variant="primary" className="d-block mx-auto" onClick={() => removeDate(slot.index)}>Remove Availability</Button>
                        </Card.Body>
                    </Card>
                );
            
            case 2: 
                return (
                    <Card key={slot.index} className="col-4 mx-2" style={{ width: '16rem' }}>
                        <Card.Body>
                        <Card.Title>Window of Availability</Card.Title>
                        <ListGroup className="p-1 mt-2 mb-3 list-group-flush">
                            <ListGroup.Item><b>Date: </b>{slot.date}</ListGroup.Item>
                            <ListGroup.Item><b>Start Time: </b>{slot.startTime}</ListGroup.Item>
                            <ListGroup.Item><b>Approx. End: </b>{slot.endTime}</ListGroup.Item>
                        </ListGroup>
                        <Card.Text className="text-muted" style={{ fontSize: '13px' }}>
                            If Loopers pick up this job, they will show up between the start time and end time you provided to complete the job.  Depending on their arrival time, they may need to stay beyond the end time, with your approval, to finish up.
                        </Card.Text>
                        <Button variant="primary" className="d-block mx-auto" onClick={() => removeDate(slot.index)}>Remove Availability</Button>
                        </Card.Body>
                    </Card>
                );
            
            default:
                return (
                    <Card key="none" className="col-4 mx-2" style={{ width: '16rem' }}>
                        <Card.Body>
                            <Card.Text className="text-center text-muted" style={{ fontSize: '13px' }}>No Date To Show...</Card.Text>
                        </Card.Body>
                    </Card>
                );
        }
    });
    
    return (
        <>
            <Card border="light" className="my-3">
                <Form.Label className="px-1 fw-bold text-center my-3">Job Dates And Times Given</Form.Label>
                <Card variant="flush" border="light" className="d-flex flex-row" style={{ overflowX: 'auto'}}>{list}</Card>
                <Form.Label className="px-1 fw-bold text-center my-3">If this is all of the dates and times the job can be completed, press Continue below!</Form.Label>
            </Card>
        </>
    );
}

export default FormAvailabilityDates;