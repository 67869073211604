import React from 'react';
import Inf from '../../assets/images/Bonaloop-web-header-logo.png';
import facebook from '../../assets/svg/facebook.svg';
import twitter from '../../assets/svg/twitter.svg';
import instagram from '../../assets/svg/instagram.svg';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../css/styles.css';
import * as bona from '../../config/constants';

function StrippedFooter() {
  return (
    <>
        <Container fluid="xlg" as="footer" className="bg-dark flex-wrap justify-content-between align-items-center px-3 pb-3">
            <Row>
                <Col className="text-muted d-flex align-items-center">
                    <Image className="mx-2" src={Inf} alt="Bonaloop Logo" height="30" width="30" />
                    &copy; {(new Date().getFullYear())} Bonaloop, LLC
                </Col>
                <Col xs="auto">
                    <ListGroup as="ul" horizontal>
                        <ListGroup.Item as="li" className="bg-dark" action href={bona.socials.facebook}><Image src={facebook} alt="Bonaloop Facebook" height="25" width="25"/></ListGroup.Item>
                        <ListGroup.Item as="li" className="bg-dark" action href={bona.socials.twitter}><Image src={twitter} alt="Bonaloop Twitter" height="25" width="25" /></ListGroup.Item>
                        <ListGroup.Item as="li" className="bg-dark" action href={bona.socials.instagram}><Image src={instagram} alt="Bonaloop Instagram" height="25" width="25" /></ListGroup.Item>
                    </ListGroup>        
                </Col>
            </Row>
        </Container>
    </>
  );
}

export default StrippedFooter;