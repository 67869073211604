import Job from './jobFeedJobCard';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import * as bona from "../../config/constants";
import * as sys from '../../config/variables';
import {useState, useEffect} from 'react';

function JobFeed (props) {
    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        const fetchJobs = async () => {
            const response = await fetch(`${bona.links.api}/functions/getJobFeed`);
            const jobsIn = await response.json();
            setJobs(jobsIn);
        };

        if (!sys.maintenance) {
            fetchJobs();
        }
    }, [props]);

    const jobList = jobs.map(key => {
        const rawBonus = key.serviceArea + key.patronIncentive;
        var formattedBonus = (Math.round(rawBonus * 100) / 100).toFixed(2);
        if (formattedBonus === '0.00') {
            formattedBonus = null;
        }

        const rawHotJob = key.hotJob;
        var formattedHotJob = (Math.round(rawHotJob * 100) / 100).toFixed(2);
        if (formattedHotJob === '0.00') {
            formattedHotJob = null;
        }

        const startDateParts = key.startDate.split("-");
        const excess = startDateParts[2].split("T");
        const startDate = new Date(startDateParts[0], startDateParts[1], excess[0]);

        const timePartsStart = key.startTime.split(":");
        const startTime = new Date('2022', '10', '12', timePartsStart[0], timePartsStart[1]);
        const startAmPm = timePartsStart[0] >= 12 ? 'pm' : 'am';
        var startHour = timePartsStart[0] % 12;
        startHour = startHour ? startHour : 12; // the hour '0' should be '12'
        const startMinutes = timePartsStart[1];
        const formattedStartTime = startHour + ':' + startMinutes + ' ' + startAmPm;

        const timePartsEnd = key.endTime.split(":");
        const endTime = new Date('2022', '10', '12', timePartsEnd[0], timePartsEnd[1]);
        const endAmPm = timePartsEnd[0] >= 12 ? 'pm' : 'am';
        var endHour = timePartsEnd[0] % 12;
        endHour = endHour ? endHour : 12; // the hour '0' should be '12'
        const endMinutes = timePartsEnd[1];
        const formattedEndTime = endHour + ':' + endMinutes + ' ' + endAmPm;

        const duration = Math.abs(endTime - startTime) / 36e5;

        const rawEstimation = duration * key.looperRate + rawBonus + rawHotJob;
        const estimation = (Math.round(rawEstimation * 100) / 100).toFixed(2);

        const remaining = key.preferredNumOfLoopers - key.partyCount;

        return (
            <Job jobID={key.jobID} estimation={estimation} jobType={key.name} jobDescription={key.jobDescription} payRate={key.looperRate} dateType={key.dateType} startDate={startDate} startTime={formattedStartTime} endTime={formattedEndTime} duration={duration} hotJob={formattedHotJob} bonus={formattedBonus} remaining={remaining} apply={props.apply} city={key.city} state={key.state} />
        );
    });

    return (
        <>
            <Card className="d-flex flex-row flex-wrap justify-content-between bg-dark" border="dark">
                {jobs.length > 0 && jobList}
                <Card key="footerCard" className="px-0 my-2 w-100" border="light">
                    <Card.Body className="m-0 px-2 py-1">
                        <Card.Title className="pt-1">Looking for something?</Card.Title>
                        <Card.Subtitle className="pt-1 pb-3"><i>There are no open jobs left to display...</i></Card.Subtitle>
                        <Card.Text className="m-0 py-1 text-center">What would you like to do?</Card.Text>
                    </Card.Body>
                    <Card.Footer className="m-0 px-2 py-1 d-flex flex-row flex-wrap justify-content-around">
                        <Button variant="danger" disabled={sys.maintenance || !sys.jobFeed} href={bona.page.looperDash}>Return to your Dash</Button>
                        <Button variant="danger" disabled={sys.closed} href={bona.page.help}>Get Help</Button>
                    </Card.Footer>
                </Card>
            </Card>
        </>
    );
}

export default JobFeed;