import React from 'react';
import Card from 'react-bootstrap/Card';

function BigIntro(props) {
  return (
    <>
      <Card border="dark">
        <Card.Body className="lead bg-dark text-white">
          <Card.Title className="display-5 pfw-bold text-white">
            {props.title}
          </Card.Title>
          <Card.Text>
            {props.text}
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
}

export default BigIntro