import Form from 'react-bootstrap/Form';

function FormEmail(props) {
    return (
        <>
            <Form.Group className="mb-3">
                <Form.Label className="px-1 fw-bold">{props.label}</Form.Label>
                <Form.Control type="email" placeholder={props.placeholder} value={props.value} onChange={props.onChange} onBlur={props.onBlur} />
                <Form.Text className="text-muted">{props.subText}</Form.Text>
            </Form.Group>
        </>
    );
}

export default FormEmail;