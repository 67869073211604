import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

function FormLooperCount(props) {
    const decrease = () => {
        if (props.value - 1 >= 1) {
            props.onChange(prev => prev - 1);
        }
    }
    return (
        <>
            <Form.Group className="d-flex flex-column mb-3">
                <Form.Label className="px-1 fw-bold">{props.label}</Form.Label>
                <Form.Group className="d-flex align-items-center justify-content-center gap-5">
                    <Button variant="outline-primary" className="px-4 mx-5 fw-bold fs-4" onClick={decrease}>-</Button>
                    {props.value}
                    <Button variant="outline-primary" className="mx-5 px-4 fw-bold fs-4" onClick={() => props.onChange(prev => prev + 1)}>+</Button>
                </Form.Group>
                <Form.Text className="text-muted">{props.subText}</Form.Text>
            </Form.Group>
        </>
    );
}

export default FormLooperCount;