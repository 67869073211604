import React, {useState} from 'react';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import Card from 'react-bootstrap/Card';
import * as bona from '../../../config/constants';
import * as sys from '../../../config/variables';

function FormPhoneCarrierPicker(props) {
    const [carriers, setCarriers] = useState([]);
    
    const fetchPhoneCarriers = async () => {
        if (!sys.maintenance) {
            const response = await fetch(`${bona.links.currAPI}/phones/carriers/all`);
            const carriersIn = await response.json();
            setCarriers(carriersIn);
        }
    };

    const ddoutput = carriers.map(key => {
        return (
            <Dropdown.Item key={key.carrierID} eventKey={key.name} onClick={() => props.setCarrierID(key.carrierID)}>{`${key.name}`}</Dropdown.Item>
        );
    });

    return (
        <>
            <Card border="light" className="my-3" onClick={fetchPhoneCarriers}>
                <Form.Group className="mb-3">
                    <Form.Label className="px-1 fw-bold">{props.label}</Form.Label>
                    <Dropdown aria-label={props.label} onSelect={(e) => props.setCarrierName(e)} value={props.value}>
                        <Dropdown.Toggle variant="secondary">{props.showMe || props.instructions}</Dropdown.Toggle>
                        <Dropdown.Menu variant="secondary" className="overflow-scroll" style={{maxHeight: "200px"}}>{ddoutput}</Dropdown.Menu>
                    </Dropdown>
                    <Form.Text className="text-muted">{`Selected Phone Carrier: ${props.showMe}`}</Form.Text>
                </Form.Group>
            </Card>
        </>
    );
}

export default FormPhoneCarrierPicker;