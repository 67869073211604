import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import * as bona from '../../config/constants';

function PatronClosed (props) {
    return (
        <Modal show={props.showing} onHide={() => props.setShowing(false)} size="lg" centered>
            <Modal.Header className="jusify-content-between h4" closeButton>Attention Patrons:</Modal.Header>
            <Modal.Body>
                <p className="h5">We regret to inform you we are closed and no longer accepting jobs.</p>
                <p>It seems so long ago we were able to keep up with demands and then COVID turned our world as we knew it upside down and shook it up.  Students were not allowed to return to campuses around the world; Citizens of this country were much more hesitant to invite students into their homes; Health and Safety regulations were put in place that hindered our ability to operate as a resource to anyone; By the end of it, our ability to continue to recruit Loopers was severely deminished to the point where our turnover was too great to remain open.</p>
                <p>And though it's very easy to say that nobody out there wants to work and that the economy and the workforce are changing, we at Bonaloop have not given up hope and want to continue the fight.</p>
                <p className="h5">Plain and simple, if Bonaloop is to ever come back, we need more Loopers/College Students.</p>
                <p>If you believe in this student resource as much as we do, just know we are still trying to make a comeback and you can help!</p>
                <p className="h5">Know a potentially amazing Looper? Get them to sign up!</p>
                <p>Looper signup is still available and we too will also be actively out at campuses and wherever else we see fit trying to recruit.</p>
                <p className="h5">Help us keep the dream alive! Don't know any potential Loopers? You can always gift Bonaloop any disposable income you may have by clicking the link below.</p>
                <p>This money will go towards keeping our site up, improving our recruiting process, and any other marketing efforts we make to make a return.  Please note that since we are NOT a non-profit business, any monetary gift you give to Bonaloop is NOT tax deductible and there will be no refunds.</p>
                <p className="h5">We truly hope to make a return and want to thank you for visiting us today.</p>
            </Modal.Body>
            <Modal.Footer className="justify-content-around">
                <Button href='#' variant="link">Contribute to the marketing budget here</Button>
                <span>{bona.patronHome.modalClosedSpanText}</span>
            </Modal.Footer>
        </Modal>
    );
}

export default PatronClosed;