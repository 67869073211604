import React from 'react';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Logo from '../assets/images/Bonaloop-web-header-logo.png';
import Inf from '../assets/images/Bonaloop-web-header-logo.png';
import DynamicNav from '../components/nav';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LargeFooter from '../components/footer/LargeFooter';
import ListGroup from 'react-bootstrap/ListGroup';
import CardGroup from 'react-bootstrap/CardGroup';
import JobTypes from '../components/cards/JobTypes';
import {useState} from 'react';
import ClosedNotice from '../components/alerts/looperClosed';
import LooperClosedModal from '../components/modals/looperClosed';
import ShareToLoopers from '../components/modals/shareToLoopers';
import * as sys from '../config/variables';
import * as bona from '../config/constants';

function Loopers() {
  const [showing, setShowing] = useState(false);
  const [sharing, setSharing] = useState(false);
  
  const jobTypes = bona.loopers.jobtype.map((jobtype, index) =>
    <Col key={index}><JobTypes key={jobtype.name} name={jobtype.name} description={jobtype.description} icon={jobtype.icon} /></Col>
  );
  return (
    <>
      <Container fluid as="main" className="bg-dark no-border">
        <DynamicNav />
        <ClosedNotice setShowing={setShowing}/>
        <LooperClosedModal showing={showing} setShowing={setShowing} setSharing={setSharing} />
        <ShareToLoopers sharing={sharing} setSharing={setSharing} />
        <Carousel fade style={{height: '90vh'}}>
          <Carousel.Item interval={7000}>
            <Image
              src={Logo}
              alt="First slide"
            />
            <Carousel.Caption>
              <h1>Are you a college student?</h1>
              <p>Our platform gives dedicated college students the unique opportunity to work with ultimate flexibility while making a competitive hourly wage with the potential for incredible bonuses.  If you are reliable, friendly, diligent, and enthusiastic, let us help connect you with your community.</p>
              <ButtonGroup size="sm" className="d-inline-flex flex-wrap gap-1">  
                <Button className="mx-2" href={bona.page.signup} disabled={sys.closed}>Start Signup</Button>
                <Button className="mx-2" href={bona.page.login} disabled={sys.closed}>Login Now</Button>
              </ButtonGroup>
            </Carousel.Caption> 
          </Carousel.Item>
          <Carousel.Item interval={7000}>
            <Image
              src={Logo}
              alt="Second slide"
            />
            <Carousel.Caption>
              <h3>Need flexible hourly work?</h3>
              <p>Loopers work only when they sign up to. No limits! How much you earn is up to you!</p>
              <ButtonGroup size="sm" className="d-inline-flex flex-wrap gap-1">  
                <Button className="mx-2" href={bona.page.signup} disabled={sys.closed}>Start Signup</Button>
                <Button className="mx-2" href={bona.page.login} disabled={sys.closed}>Login Now</Button>
              </ButtonGroup>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={10000}>
            <Image
              src={Logo}
              alt="Third slide"
            />
            <Carousel.Caption>
              <h3>Want to learn more about being a Looper?</h3>
              <p>Click any of the buttons to learn more!</p>
              <ButtonGroup size="sm" className="d-inline-flex flex-wrap gap-1">
                <Button className="mx-2" href="#whatis">What is a Looper?</Button>
                <Button className="mx-2" href="#services">What do Loopers do?</Button>
                <Button className="mx-2" href="#benefits">What are some benefits?</Button>
              </ButtonGroup>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
        <Container fluid className="bg-danger">
          <Container variant="flush" className="bg-danger">
            <Card border="danger" bg="danger" text="dark" id="whatis">
              <Card.Header as="h1" className="my-4">What is a Looper?</Card.Header>
              <Card.Body className="px-0 mx-0">
                <CardGroup className="text-center d-inline-flex flex-wrap gap-2">
                  <Card border="light" bg="dark" text="light">
                    <Card.Header as="h5">Loopers are...</Card.Header>
                    <Card.Body>
                      <Card.Title as="h3">College Students</Card.Title>
                      <Card.Text>High School seniors that have accepted an offer for college through recent college graduates (within a year) can be Loopers.  You just need your school email address!</Card.Text>
                    </Card.Body>
                  </Card>
                  <Card border="light" bg="dark" text="light">
                    <Card.Header as="h5">Loopers provide...</Card.Header>
                    <Card.Body>
                      <Card.Title as="h3">Service</Card.Title>
                      <Card.Text>Loopers grab contracts from the site ranging anywhere from 10 minutes to several hours spaning over a dozen different job types and get paid to help complete them.</Card.Text>
                    </Card.Body>
                  </Card>
                  <Card border="light" bg="dark" text="light">
                    <Card.Header as="h5">Loopers receive...</Card.Header>
                    <Card.Body>
                      <Card.Title as="h3">Benefits</Card.Title>
                      <Card.Text>Scroll down to learn more!</Card.Text>
                      <Card.Text>- OR -</Card.Text>
                      <Card.Link href="#benefits" className="text-white">Click Here to Jump!</Card.Link>
                    </Card.Body>
                  </Card>
                </CardGroup>
                <Card border="light" bg="dark" text="light" className="mt-5 mx-0 px-0">
                    <Card.Header as="h5" className="text-center fw-bold">Expectations</Card.Header>
                    <Card.Body className="d-inline-flex justify-content-between gap-3 m-0 p-3">
                      <Card border="dark" bg="dark" text="light" className="m-0 p-0 w-100">
                        <Card.Title className="mt-2 text-center">Loopers...</Card.Title>
                        <Card.Body className="p-0 m-0">
                          <ListGroup className="rounded-3 m-0 p-0" variant="flush">
                            <ListGroup.Item variant="dark">Have some experience in the service industry</ListGroup.Item>
                            <ListGroup.Item variant="dark">Are highly organized with their time and responsibilities</ListGroup.Item>
                            <ListGroup.Item variant="dark">Do not struggle keeping their commitments</ListGroup.Item>
                            <ListGroup.Item variant="dark">Get a high sense of reward from completing hard work</ListGroup.Item>
                            <ListGroup.Item variant="dark">Have some experience working on projects in at least 1 of our job types listed below</ListGroup.Item>
                            <ListGroup.Item variant="dark">Can balance their academics with extracurricular activities and work without being overwhelmed</ListGroup.Item>
                            <ListGroup.Item variant="dark">Have money management skills</ListGroup.Item>
                            <ListGroup.Item variant="dark">Have systems in place to commute within their area or have their own personal vehicles</ListGroup.Item>
                          </ListGroup>
                        </Card.Body>
                      </Card>
                      <Card border="dark" bg="dark" text="light" className="m-0 p-0 w-100" id="howitworks">
                        <Card.Title className="mt-2 text-center">How it works...</Card.Title>
                        <Card.Body className="m-0 p-0">
                          <ListGroup className="rounded-3 m-0 p-0" variant="flush">
                            <ListGroup.Item variant="dark">Patrons will post jobs on the feed as the deem necessary</ListGroup.Item>
                            <ListGroup.Item variant="dark">You will visit the feed as regularly as you wish and claim the jobs you wish to do</ListGroup.Item>
                            <ListGroup.Item variant="dark">During the claiming process, you will be given all the details for the job including it's estimated total earnings and status</ListGroup.Item>
                            <ListGroup.Item variant="dark">Once the status is locked and you get an email to confirm it, you'll go complete the job alone or if the job requires more than one Looper, with the other Loopers that also claimed the job</ListGroup.Item>
                            <ListGroup.Item variant="dark">Upon conclusion of the job, you'll report how it went here on the site by closing and rating the job.</ListGroup.Item>
                            <ListGroup.Item variant="dark">Once the Patron confirms your report, you get paid within 72 hours (but usually within 24) what you've earned, plus any bonuses and tips!</ListGroup.Item>
                          </ListGroup>
                        </Card.Body>
                      </Card>
                    </Card.Body>
                  </Card>
              </Card.Body>
            </Card>
          </Container>
        </Container>
        <Container variant="fluid" className="bg-dark text-white no-border mt-4">
          <Card border="dark" className="bg-dark" id="services">
            <Card.Header as="h1">What do Loopers do?</Card.Header>
            <Card.Title as="span" className="mt-4 text-center">
              Loopers pick up contracts with local Patrons through the Bonaloop job feed in the following list of our most common job types.  
            </Card.Title>
            <Card.Subtitle className="text-muted text-center">  
              Pay per hour varies on job type and is available for you to review right from the footer on our Pay Rates page. 
            </Card.Subtitle>
            <Card.Body>
              <Row xs={1} md={2} lg={3} xl={3} className="g-4 mt-3">
                {jobTypes}
              </Row>
            </Card.Body>
          </Card>
        </Container>
        <Container fluid className="bg-danger">
          <Container fluid className="bg-danger pb-5">
            <h1 className="py-5 mx-4" id="benefits">What are some benefits?</h1>
            <Card border="dark" bg="dark" text="dark" className="mt-4 rounded-0">
              <Card.Title as="h2" className="bg-dark text-white text-center pt-3">Personal Benefits</Card.Title>
              <Card.Body>
                <CardGroup className="text-center">
                  <Card border="dark" bg="dark" text="light" className="mx-1">
                    <Card.Header as="h5">Unlimited Earning Potential</Card.Header>
                    <Card.Body className="bg-white text-black">
                      <Card.Title as="h3">Need Money?</Card.Title>
                      <Card.Text>Not only are you always making above minimum wage, but sometimes you are making double! What's better than that you might ask? Try being able to pick up contracts as infrequently or as often as you'd like and getting paid for their completion within the same day to 72 hours later! How much you earn is up to you!</Card.Text>
                    </Card.Body>
                  </Card>
                  <Card border="dark" bg="dark" text="light" className="mx-1">
                    <Card.Header as="h5">100% Flexible Scheduling</Card.Header>
                    <Card.Body className="bg-white text-black">
                      <Card.Title as="h3">Busy life?</Card.Title>
                      <Card.Text>As a student, life can get pretty crazy! All the responsibilities, commitments, and studies can easily overwhelm anyone! It's because of this that we let YOU decide when you are free to work and grab a contract on the site.  Got a free period today and want to make a buck? Done studying or with practice early? Got books or taxes you need to pay for? Check the job feed for an opportunity anytime and capitalize!</Card.Text>
                    </Card.Body>
                  </Card>
                  <Card border="dark" bg="dark" text="light" className="mx-1">
                    <Card.Header as="h5">No Investment Required</Card.Header>
                    <Card.Body className="bg-white text-black">
                      <Card.Title as="h3">No tools? No problem!</Card.Title>
                      <Card.Text>Despite the services we offer to help with, Patrons are required to provide your with all the tools you'd need to assist them in completing their projects, inclusive of materials.  This makes it easy as a couple clicks to grab a job of any type that you are comfortable doing and quickly getting started as scheduled - no need for preparing a plan, buying tools, or figuring out logistics! </Card.Text>
                    </Card.Body>
                  </Card>
                </CardGroup>
                <CardGroup className="text-center">
                  <Card border="dark" bg="dark" text="light" className="mt-5">
                    <Card.Header as="h3" className="bg-white text-black">Additional Optional Benefits</Card.Header>
                    <Card.Body className="d-flex justify-content-between row">
                      <Card border="dark" bg="dark" text="light" className="col-lg-12">
                        <Card.Title className="mt-2">Contract Benefits</Card.Title>
                        <Card.Body>
                          <ListGroup className="justify-content-end text-start" variant="flush">
                            <ListGroup.Item>
                              <h3>Hot Jobs</h3>
                              <ListGroup variant="flush">
                                <ListGroup.Item>Hot jobs are jobs that are on the feed that carry a higher level of urgency for one reason or another and therefore have an additional bonus added to them that gets paid just for completing the job.</ListGroup.Item>
                                <ListGroup.Item>This is not a tip paid by the Patron.  It's an incentive added by Bonaloop for Loopers to pick up the contract.</ListGroup.Item>
                              </ListGroup>
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <h3>Bonus Jobs</h3>
                              <ListGroup variant="flush">
                                <ListGroup.Item>Similar to a Hot Job, Bonus Jobs are jobs on the feed that the Patron has added financial incentive to in order to motivate Loopers to pick up the contract.</ListGroup.Item>
                                <ListGroup.Item>This is not a tip for completing the job.  It's an incentive to pick it up paid for in addition to the hourly rate by the Patron and any further tips for quality.</ListGroup.Item>
                              </ListGroup>
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <h3>Insurance</h3>
                              <ListGroup variant="flush">
                                <ListGroup.Item>Every job you pick up off the feed is covered by our Liability Insurance at our expense so that you do not have to get your own policy.  Liability insurance protects against costs incurred from damages, injury, lawsuits and much more.</ListGroup.Item>
                                <ListGroup.Item>We also cover you with Workers Compensation Insurance in the case of injury to yourself while on the job.  This protection is meant to work alongside your own personally attained medical insurance.</ListGroup.Item>
                              </ListGroup>
                            </ListGroup.Item>
                          </ListGroup>
                        </Card.Body>
                      </Card>
                      <Card border="dark" bg="dark" text="light" className="col-lg-12">
                        <Card.Title className="mt-2">Bilateral Benefits</Card.Title>
                        <Card.Body>
                        <ListGroup className="justify-content-start text-start" variant="flush">
                            <ListGroup.Item>
                              <h3>Take it, Make It</h3>
                              <ListGroup variant="flush">
                                <ListGroup.Item>Any pictures you take related to Bonaloop can be submitted to us and should we use them on our socials or site, you will be compensated per picture! No limits on how many you submit!</ListGroup.Item>
                                <ListGroup.Item>So whether or not they're before and after pictures or pictures of you actually doing the job, get them in to us! If you need or want some branding or merch, we will happily help you get some!</ListGroup.Item>
                              </ListGroup>
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <h3>Referral Bonuses</h3>
                              <ListGroup variant="flush">
                                <ListGroup.Item>Loopers are often in the biggest Looper pool Bonaloop could ever hope to tap: schools.  The more Loopers Bonaloop has to offer it's Patrons, the more opportunities Bonaloop can create for it's Loopers!</ListGroup.Item>
                                <ListGroup.Item>Given this, for every roommate, friend, teammate, ex, neighbor, crush, sibling, cousin, and any other type of referral you make that becomes a Looper and does their first job with us, you will be compensated! No limits on how many referrals you make!</ListGroup.Item>
                              </ListGroup>
                            </ListGroup.Item>
                          </ListGroup>
                        </Card.Body>
                      </Card>
                    </Card.Body>
                  </Card>
                </CardGroup>
                <CardGroup className="text-center my-5 bg-dark">
                  <Card border="light" bg="light" text="dark" className="mx-1">
                    <Card.Header as="h2">Scholarships</Card.Header>
                    <Card.Body className="bg-white text-black">
                      <Card.Text>Bonaloop offers Loopers that work hard for their Patrons and have more school left to do the ability to apply for our scholarship and get some financial assistance once a year.  More information on this will be given during orientation.<br/>We also work with local partners and non-profits to have other scholarship opportunities available for our Loopers and listed here on the site.<br/>We also award book grants for those unexpected school material needs you might incur!</Card.Text>
                    </Card.Body>
                  </Card>
                  <Card border="light" bg="light" text="dark" className="mx-1">
                    <Card.Header as="h2">Networking</Card.Header>
                    <Card.Body className="bg-white text-black">
                      <Card.Text>When Patrons open their accounts to be able to post, they disclose to us what they do for work and if they are open to networking just as you will when you sign up for your Looper account and disclose what you are studying in school.  When possible, we will happily facilitate meet ups between you and your fellow Loopers as well as major/industry related meet ups so you can find the perfect internship or permanent employment opportunity as well as bounce questions off of real people that can serve as valuable resources and are still in the field.</Card.Text>
                    </Card.Body>
                  </Card>
                </CardGroup>
              </Card.Body>
            </Card>
          </Container>
        </Container>
        <Container fluid className="bg-dark text-white my-5 text-center">
          <Card border="dark" bg="dark" text="danger" className="pt-2" id="cta">
            <Card.Img variant="top" src={Inf} style={{width: '200px'}} className="mx-auto align-center" />
            <Card.Title as="h1" className="mt-5">You ready? What are you waiting for?</Card.Title>
            <Card.Body>
                <Card.Text as="h3" className="mb-5">Get started!</Card.Text>
                <Button size="lg" className="m-2" href={bona.page.signup} disabled={sys.closed}>Click Here to Sign Up</Button>
                <Button size="lg" className="m-2" href={bona.page.login} disabled={sys.closed}>Click Here to Sign In</Button>
            </Card.Body>
          </Card>
        </Container>
        <LargeFooter />
      </Container>
    </>
  );
}

export default Loopers;