import Button from 'react-bootstrap/Button';

function FormButton(props) {
    return (
        <>
            <Button variant={props.variant} className="w-100 mb-2 btn btn-lg btn-primary" type="submit" disabled={props.disabled || false} onClick={props.onClick}>{props.label}</Button>
        </>
    );
}

export default FormButton;