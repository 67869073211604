import Job from './dashboardJobCard';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import {useState, useEffect} from 'react';
import * as bona from '../../config/constants';
import * as sys from '../../config/variables';
import {useLocation} from 'react-router-dom';

function CurrentJobs (props) {
    const location = useLocation();
    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        const fetchJobs = async () => {
            const response = await fetch(`${bona.links.api}/functions/getJobByAssetID?assetType=${props.assetType}&assetID=${props.assetID}&filter=${props.filter}`);
            const jobsIn = await response.json();
            setJobs(jobsIn);
        };

        if (!sys.maintenance) {
            fetchJobs();
        }
    }, [props]);

    const jobList = jobs.map(key => {
        const rawBonus = key.serviceArea + key.patronIncentive;
        var formattedBonus = (Math.round(rawBonus * 100) / 100).toFixed(2);
        if (formattedBonus === '0.00') {
            formattedBonus = null;
        }

        const rawHotJob = key.hotJob;
        var formattedHotJob = (Math.round(rawHotJob * 100) / 100).toFixed(2);
        if (formattedHotJob === '0.00') {
            formattedHotJob = null;
        }

        const startDateParts = key.startDate.split("-");
        const excess = startDateParts[2].split("T");
        const startDate = new Date(startDateParts[0], startDateParts[1], excess[0]);

        const timePartsStart = key.startTime.split(":");
        const startTime = new Date('2022', '10', '12', timePartsStart[0], timePartsStart[1]);
        const startAmPm = timePartsStart[0] >= 12 ? 'pm' : 'am';
        var startHour = timePartsStart[0] % 12;
        startHour = startHour ? startHour : 12; // the hour '0' should be '12'
        const startMinutes = timePartsStart[1];
        const formattedStartTime = startHour + ':' + startMinutes + ' ' + startAmPm;

        const timePartsEnd = key.endTime.split(":");
        const endTime = new Date('2022', '10', '12', timePartsEnd[0], timePartsEnd[1]);
        const endAmPm = timePartsEnd[0] >= 12 ? 'pm' : 'am';
        var endHour = timePartsEnd[0] % 12;
        endHour = endHour ? endHour : 12; // the hour '0' should be '12'
        const endMinutes = timePartsEnd[1];
        const formattedEndTime = endHour + ':' + endMinutes + ' ' + endAmPm;

        const duration = Math.abs(endTime - startTime) / 36e5;

        var rawEstimation = 0.00;
        var payRate = 0.00;
        if (props.assetType === "Patron") {
            rawEstimation = duration * key.patronRate + rawBonus;
            payRate = key.patronRate;
        }
        if (props.assetType === "Looper") {
            rawEstimation = duration * key.looperRate + rawBonus + rawHotJob;
            payRate = key.looperRate;
        }
        const estimation = (Math.round(rawEstimation * 100) / 100).toFixed(2);

        const remaining = key.preferredNumOfLoopers - key.partyCount;

        return (
            <Job key={`${key.jobID}${key.jobDateID}`} jobID={key.jobID} jobDate={key.jobDateID} estimation={estimation} jobType={key.name} jobDescription={key.jobDescription} payRate={payRate} dateType={key.dateType} startDate={startDate} startTime={formattedStartTime} endTime={formattedEndTime} duration={duration} hotJob={formattedHotJob} bonus={formattedBonus} remaining={remaining} edit={props.edit} close={props.close} cancel={props.cancel} withdraw={props.withdraw} pay={props.pay} preferredNumOfLoopers={key.preferredNumOfLoopers} city={key.city} state={key.state} status={key.status} assetType={props.assetType} caller="currentJobs" uponArrival={key.uponArrival} />
        );
    });

    return (
        <Accordion.Item eventKey="currentJobs" key="currentJobs">
            <Accordion.Header><b>Current Jobs</b></Accordion.Header>
            <Accordion.Body className="d-flex flex-row flex-wrap justify-content-between bg-dark p-1">
                {jobs.length > 0 && jobList}
                <Card key="footerCard" className="px-0 my-2 w-100" border="light">
                    <Card.Body className="m-0 px-2 py-1">
                        <Card.Title className="pt-1">Looking for something?</Card.Title>
                        <Card.Subtitle className="pt-1 pb-3"><i>There are no current jobs left to display...</i></Card.Subtitle>
                        <Card.Text className="m-0 py-1 text-center">What would you like to do?</Card.Text>
                    </Card.Body>
                    <Card.Footer className="m-0 px-2 py-1 d-flex flex-row flex-wrap justify-content-around">
                        {location.pathname === bona.page.patronDash && <Button variant="danger" disabled={!sys.jobSubmission || sys.maintenance} href={bona.page.submitajob}>Submit a New Job</Button>}
                        {location.pathname === bona.page.looperDash && <Button variant="danger" disabled={sys.maintenance} href="#">Close/Rate (unimplemented)</Button>}
                        <Button variant="danger" disabled={sys.closed} href={bona.page.help}>Get Help</Button>
                    </Card.Footer>
                </Card>
            </Accordion.Body>
        </Accordion.Item>
    );
}

export default CurrentJobs;