import lawnAndYard from '../assets/images/icons/LawnAndYard.png';
import cleaning from '../assets/images/icons/Cleaning.png';
import dirtyWork from '../assets/images/icons/DirtyWork.png';
import errands from '../assets/images/icons/Errands.png';
import events from '../assets/images/icons/Events.png';
import houseAndPet from '../assets/images/icons/HouseAndPet.png';
import moving from '../assets/images/icons/Moving.png';
import other from '../assets/images/icons/Other.png';
import painting from '../assets/images/icons/Painting.png';
import pets from '../assets/images/icons/Pets.png';
import shoveling from '../assets/images/icons/Shoveling.png';
import specialty from '../assets/images/icons/Specialty.png';

export const looperHome = {
    alertClosedHeading: "NOTICE: Looper signups are now open! Once we have enough student activity in your area, we will open for business.  Sign up today and then go tell your friends!",
    alertClosedCTA: "FOR FURTHER DETAILS, CLICK HERE.",
    modalClosedSpanText: "Last Reviewed: 11/15/2022",
}

export const patronHome = {
    alertClosedHeading: "ATTENTION: WE ARE CLOSED AND NOT ACCEPTING JOB LISTINGS AT THIS TIME. THANK YOU.",
    alertClosedCTA: "FOR FURTHER DETAILS, CLICK HERE.",
    modalClosedSpanText: "Last Reviewed: 11/15/2022",
}

export const signupPicker = {
    loopers: {
        header: "Create a Looper Account?",
        desc: "Looper accounts are for students looking to take advantage of Bonaloop's resources for labor gigs, networking with field experts in professional industries, and networking with other fellow Loopers.",
    },
    patrons: {
        header: "Create a Patron Account?",
        desc: "Patron accounts are for community members looking to post gig jobs for Loopers and possibly volunteer their expert advise in their respective industry of work.",
    },
}

export const exclusions = {
    title: "Service Exclusions",
    subheading: 'While we supply work in a vast array of categories, there are certain services that our insurance policy does not cover and therefore we CANNOT provide. We kindly ask that you refrain from requesting work that involves any of the following categories. Thank you as always!',
    exclusionsList: ["Babysitting (any supervision of children)", "Elder care", "Any form of human transportation", "Exterior painting (staining previously unpainted surfaces is okay)", "Operating of power saws or chainsaws", "Going onto any rooftops", "Climbing ladders that are above 4' off the ground", "Serving of alcoholic beverages"],
}

export const faq = {
    title: "Frequently Asked Questions",
    subheading: "Below is a list of our most frequently asked questions and their answers. If you have a question that cannot be found on the list below, please do not hesitate to reach out to us and we will be more than glad to assist you! Our contact information can always be found at the bottom of any page.",
    faqna: [
        { 
        question: "Is Bonaloop a professional [fill in the blank: Landscape, Painting, Moving, etc] Company?", 
        answer: "No! While we do strive for our Loopers to carry a professional work etiquette on the job, we do not train the Loopers in any of our categories, nor do we compete with professional services.<br><br>Bonaloop is strictly a connector to insured college students that work as an extra set of hands, directly for you, using your tools, equipment and direction.<br><br>Loopers can help you with moving, painting, raking, weeding & more under your direction, but please consider carefully if your request and expectation is better suited for a professional moving company, painter, landscaper etc.",
        },
        {
        question: "What is your service area?",
        answer: "Our service area to you is based on where you are located from the schools that fall within our service area. A school is marked to be within our service area if we currently have 5 or more active Loopers attending the school.<br><br><ul><li>If the address of the job is 30 minutes or less (driving) from the nearest in-service-area school, then the job is within our service area</li><li>If the address of the job is longer than 30 minutes (driving) from the nearest in-service-area school, then the job is outside of our service area</li><li>If the job is outside of the service area, there will be a $15 flat fee that will go directly to the Looper(s). In addition, the travel time to and from the job will be added onto the total cost of the job (per Looper)</li><li>All out-of-service area fees will be predetermined before the job</li></ul>",
        },
        {
        question: "Do Loopers have their own tools and equipment?",
        answer: "Typically no. Loopers are all college students that come work directly for you using your tools, materials and equipment.",
        },
        {
        question: "Do you supply moving trucks?",
        answer: "Similar to above, no.<br><br>Some of the Loopers do have their own pick-up truck or SUV that could be used on moving jobs, but we do not supply company trucks.<br><br>If you request specifically that a Looper uses their own pick-up truck or SUV to transport your items, there will be an additional $25 charge that goes directly to the Looper for the use of their vehicle. This fee will be agreed upon before the job.",
        },
        {
        question: "What are the details for winter shoveling?",
        answer: "Bonaloop is not a snow removal company in that it cannot guarantee a Looper can be available for every storm, but is happy to connect you with college students when Loopers are available. To request snow removal, simply post a job as normal through your Patron dashboard, but no earlier than a day before a predicted snowfall given the variability of weather.<br><br><b>Note:</b> Our liability insurance does not cover “Slip and Fall”: While Loopers are covered against any injury with our workers’ compensation insurance, if anyone else were to be injured because of snow and ice, Bonaloop or the Looper cannot be held responsible.",
        },
        {
        question: "How do I pay for the job?",
        answer: "Since COVID-19, we have implemented a non-contact payment process at the conclusion of the job. You are able to pay for the job right through your Patron dashboard. Only jobs that are in the <b>‘Completed’</b> status can be paid for. Jobs will be marked completed once the Looper closes out the job on their end.<br><br><ol><li>Go to the <b>‘Current Jobs’</b> section.</li><li>Find the job that you are looking to pay for</li><li>Make sure it is in the <b>‘Completed’</b> status</li><li>Under the <b>‘Action’</b> column, select <b>'Pay | Tip this job’</b> and click submit</li><li>Here, you can pay via credit/debit card, Apple Pay, or PayPal and add any tips to the Looper(s). If there are multiple Loopers on the job, tips will be split accordingly.</li></ol>",
        },
        {
        question: "How are Loopers insured?",
        answer: "Bonaloop provides Loopers with Workers’ Compensation, General Liability and Hired Not Owned Vehicle insurance.",
        },
        {
        question: "Does Bonaloop provide quotes or estimates for jobs?",
        answer: "No. Our Loopers come to work for you at a fixed hourly rate (currently Patrons pay $25/hour per Looper, Loopers receive $15/hour) ($30/hour towards Patrons & $18/hour to Loopers are the current rates for snow shoveling).<br><br>Upon posting your request we ask for an estimated time duration in order to assist Loopers in scheduling their time. That estimated duration along with the number of Loopers that you request will give you a good idea of what you will pay.<br><br>Example: Sarah requests 2 Loopers to come do yard work for 4 hours, she will pay $25/hour x 2 Loopers x 4 hours: $200 total (plus a tip if Sarah is feeling generous!)",
        },
        {
        question: "What if Loopers finish sooner, do I still have to pay for the full time I estimated?",
        answer: "You only pay for actual hours worked with a 1 hour per Looper minimum.<br><br>Meaning if your job was quick and only took 20 minutes to complete, the Looper is still entitled to 1 hour of pay and the Patron must pay 1 hour of service. You will have the opportunity to review the reported hours before paying, if the Looper misreported.",
        },
        {
        question: "What if the job does not get completed within the estimated duration?",
        answer: "If the job takes longer than planned, it is entirely possible that the Loopers will need to leave due to other commitments as they are only obligated to stay the maximum duration that is agreed upon within the confirmation.<br><br>If your Looper cannot stay and you need additional help, simply go on the feed and request a new job and we will do our best to connect you with a Looper.",
        },
        {
        question: "I submitted a job but now want to see or change my post, how do I do that?",
        answer: "All current or in-progress jobs can be viewed under the ‘Current jobs’ tab within your Patron dashboard.<br><br>You are able to make changes to the job details under the ‘Action’ column for each job by selecting ‘Modify this job’, making the appropriate changes, and clicking submit.",
        },
        {
        question: "How much lead time should I give to schedule a job?",
        answer: "Jobs get posted to our private feed that all Loopers see within hours of your request, and they select jobs that interest them and fit their schedule.<br><br>There is no limitation on the lead time, but it greatly increases the chances of getting filled with the more lead time that you can give, and the more date and time options that you can offer.<br><br>Loopers are less likely to select jobs more than two weeks away given the contingency of their day-to-day schedules, but we are more than happy to get it on our Job Feed in case someone does want to commit to it.",
        },
        {
        question: "How do I get notified when my job request is filled?",
        answer: "You will be notified via a <b>\"Job LOCKED\"</b> email with all the final details once a sufficient number of Loopers have signed up for your job.<br><br>Loopers will show up at the date, time and location as listed in the email, if you spot any discrepancies please reach out to us as soon as possible.",
        },
        {
        question: "I have an outdoor job that is dependent on weather, what if it rains on the day of the job?",
        answer: "All outdoor jobs are “Weather Permitting” unless decided otherwise beforehand.<br><br>This means that either the Looper(s) or Patron can decide to cancel the job same day without penalty. (For all other jobs cancelled by the Patron in less than 24 hours, we ask them to pay the Loopers as estimated for reserving their schedule).<br><br>When cancelling the job, you will be given the opportunity to check off whether or not the job is being cancelled due to the weather. Please make sure to check this off as it will prevent any unnecessary cancellation fees.",
        },
        {
        question: "I am not a citizen of the United States, can I become a Looper?",
        answer: "Unfortunuately, no. At this time, our Loopers are only protected if they have US citzenship. We are still exploring how we can safely allow non-US citzens to work with us.",
        },
        {
        question: "Is Bonaloop a Temp Agency?",
        answer: "No, Bonaloop is simply a connector to college labor, where Loopers come work directly for you as Independent Contractors. Because Loopers are not employees of you or Bonaloop, we are careful to avoid placing Loopers in any positions that would violate Massachusetts Employment Law.<br><br>An example of this is any position temporary or otherwise with another business where the Looper would be directly working on or providing the product or service the business provides to its customers (For example Loopers should not do moving for a moving company or painting for a painting company, but Loopers could do moving for a painting company when it is moving its own offices).",
        },
        {
        question: "Does it help to post a job sooner before I am certain of the time and date details?",
        answer: "No!<br><br>This actually creates more confusion as Loopers select jobs specifically on their availability and then reserve the time selected in their schedule. Please always wait until you have date and time options you can commit to before posting (but the more time/date options you can give the better as in increases the chances it will be filled quickly).",
        },
    ],
}

export const help = {
    title: "Help Section",
    subheading: "Welcome to the Help Section! We designed this resource to assist you with completing some common tasks on the site. Please review the topics below as needed and if you require further assistance, reach out to us. We are happy to help! We will keep this updated as the site features and aesthetics change so always check here if you need any help!",
    helpArticles: [
        { 
        topic: "How To Post A Job",
        text: "<ol><li>First step, log in to your profile by clicking on the Log In button on the top right of the screen</li><li>Click the New Job button that is listed along the top of the screen, OR click on the List New Job Button that is under the new ‘Current Jobs’ or ‘Past Jobs’ section</li><li>Select a job type</li><li>Select your availability (choose either the all-day availability or the specific date & time option depending on your personal circumstances)</li><li>Select the number of Loopers that you will need to get the job done</li><li>Add a description of what the job entails</li><li>Add instructions for Loopers to follow for when they arrive to the job</li><li>Select (Y/N) for whether or not the job is outdoors</li><li>Select (Y/N) for whether or not the job requires a Looper with a vehicle</li><li>Add a guaranteed cash bonus to the job that will go 100% to the Looper to help incentivize the job</li><li>Confirm the contact details</li><li>Confirm the job address</li><li>Submit the job</li><li>Wait for the job to be reviewed by our team, and once it is approved it will be posted onto the job feed for Loopers to access</li></ol>",
        video: "<iframe width=\"560\" height=\"315\" src=\"https://www.youtube.com/embed/ospzwxdw6ZI\" title=\"YouTube video player\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen></iframe>",
        audience: "Patrons",
        },
        { 
        topic: "How To Close And Pay For A Job",
        text: "<ol><li>First step, log in to your profile by clicking on the Log In button on the top right of the screen</li><li>Once the Looper(s) submit their job completion form, a button will appear next to the job that says Close, please click that button</li><li>Confirm the details that the Looper(s) reported to us, if it all looks correct click on the Confirm and Continue button</li><li>If there are any discrepancies, please reach out to gethelp@bonaloop.com</li><li>Rate the Looper(s) out of 5 stars based upon their promptness, mannerisms, and work quality</li><li>Add any additional comments on the Looper(s) for management to review</li><li>Pay for the job by confirming the amount that you are being charged, and entering in your card number, CVV, expiration date, and zip code</li><li>Add any additional tips for the Looper (this will go 100% to the Looper)</li><li>Click the Pay $$ + Tip button</li><li>Check your email for a confirmation of payment, if you do not receive a confirmation, please reach out to gethelp@bonaloop.com</li></ol>",
        video: "<iframe width=\"560\" height=\"315\" src=\"https://www.youtube.com/embed/GuWigEEHtB0\" title=\"YouTube video player\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen></iframe>",
        audience: "Patrons",
        },
        { 
        topic: "All Day Availability vs Specific Dates & Times",
        text: "<ul><li><b>Specific Dates & Times</b><ul><li>The specific date & time option should be used if you are a Patron with a tight schedule and/or would need the job done at a very certain time. This should be used if you are a Patron who doesn’t have a lot of time on your schedule for a Looper to come by, if you have a specific event, if you are renting a U-Haul on a certain day and need the Looper within that time frame, etc.</li><li><b>Examples:</b><ul><li>You are scheduling a move for the weekend and called U-Haul, they have a truck ready for you to use Saturday at 10AM and you have dinner plans at 6 PM. You should schedule the job for 10AM to 4:30 or 5 PM.</li><li>You work weekdays 7AM -1 PM and need a Looper to come by for yard work this weekend because you are having company over. You want to be there to assist & guide the Looper. You should schedule your job for a weekday (can be multiple times), but you should list the job using the Specific Date and Time option and make your availability between 2 PM -5 PM (or whatever time you choose to end your day).</li></ul></li></ul></li><li><b>All-Day Availability</b><ul><li>The all-day availability option should be used if you are a Patron who is flexible with their time and are able to provide time window(s) where you would be available and would not mind that a Looper came within the times that you specify. Please note that if you are listing using all-day availability, it can be helpful for Loopers to provide an estimated duration of the job within the job description.</li><li><b>Example:</b><ul><li>You are available all day on Saturday and Sunday for yard work, you wouldn’t mind if a Looper came either day, as long as it fell within 9AM to 4 PM. You would list those two dates, along with a 9AM to 5 PM time frame. This would mean that if you thought the yard work would take 2 hours, you would mind if the Looper came at say, 3 PM on Sunday, to get the job done.</li></ul></li></ul></li></ul>",
        video: "<iframe width=\"560\" height=\"315\" src=\"https://www.youtube.com/embed/QoAw6IWAi5o\" title=\"YouTube video player\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen></iframe>",
        audience: "Patrons",
        },
        { 
        topic: "How To Become A Patron",
        text: "<ol><li>Click the Get Started button on the home page</li><li>Click the Patron button on the right hand side</li><li>Enter in your personal email address</li><li>Enter in a desired password</li><li>Confirm the desired password</li><li>Provide a personal 4-digit code that we can use to identify you if we ever need to reach you via alternate means of communication</li><li>Select an option from the list to let us know how you heard about us</li><li>You will be directed to Log In to your account, using the email address that you just provided and the password that you just created</li><li>Once you are logged in, you will be prompted to enter in more information</li><li>Enter your first name</li><li>Enter your last name</li><li>Enter your phone type</li><li>Enter your phone number</li><li>Enter your phone provider (we request this information to aid our ability to contact you via text message)</li><li>Enter your address type</li><li>Enter your street address. It should show up in the field that displays. Click on it please</li><li>Select your preferred gender</li><li>Enter in a referral code (if applicable)</li><li>Click Submit.</li><li>You should now be on the orientation page</li><li>Scroll down to the ‘Onboarding’ section</li><li>Click on the Complete and Sign button to review the Patron-Looper agreement</li><li>Consent to the agreement by checking off the 'Consent Here After Reading' box</li><li>Click the Thank You! Continue Here! button</li><li>Back on the ‘Onboarding’ section, click on the Upload button next to the profile picture option</li><li>Upload a profile picture of yourself from your computer to help our Loopers match a name with a face on your profile and on jobs. This is optional at this time.</li><li>Back on the Onboarding section, if you are done with all other steps above, click on the Go To Dashboard button to finish</li></ol>",
        video: "<iframe width=\"560\" height=\"315\" src=\"https://www.youtube.com/embed/JyhWttUQPxM\" title=\"YouTube video player\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen></iframe>",
        audience: "Patrons",
        },
    ],
}

export const about = {
    title: "About Us",
    subheading: "",
    desc: "Description for now",
}

export const home = {
    title: "Welcome to Bonaloop",
    desc: "Bonaloop is a rapidly growing online marketplace that connects college students in need of flexible work with residents and small businesses in need of getting short term projects completed.  If you are a resident or business owner in a New England community and want to learn more about posting a gig with us, click the 'Patrons' button below.  If you are a college student looking to learn more about picking up gigs through us, click the 'Loopers' button below.",
}

export const jobfeed = {
    title: "Job Feed",
    subheading: "Welcome to the Job Feed! This page will always have every job up for grabs to the second.  Of course, you can't actually grab any of them unless you are a Looper so if you are not a Looper yet, sign up!",
}

export const looperDash = {
    title: "Looper Dashboard",
    subheading: "Welcome to your Dashboard! This is the central hub to all your Bonaloop needs! You can navigate from here to pick up jobs, withdraw from jobs, close your jobs, review your jobs, settings, and details, and you can access your resources from here too!",
}

export const loopers = {
    jobtype: [
        { 
          name: "Lawn and Yard",
          description: "Raking, mowing, mulching, gardening, trimming, and weeding lawn and yards of any size, big or small.",
          icon: lawnAndYard,
        },
        { 
          name: "Moving Assistance",
          description: "Packing, unpacking, loading, unloading, and hauling items and properties of all sizes, big or small.",
          icon: moving,
        },
        { 
          name: "Dirty Work",
          description: "Digging, shoveling, light construction, and you guessed it, anything dirty!",
          icon: dirtyWork,
        },
        { 
          name: "Cleaning",
          description: "Cleaning any type & size of space and general organization.",
          icon: cleaning,
        },
        { 
          name: "Event Assistance",
          description: "Assisting with setting up, supporting, and breaking down events such as private parties, festivals, block parties, and more.",
          icon: events,
        },
        { 
          name: "Painting",
          description: "Interior painting of walls, ceilings, furniture, and more. Exterior is allowed when staining previously unpainted fences, decks and furniture. You may not remove, handle, or apply lead paint.",
          icon: painting,
        },
        { 
          name: "Errands and Delivery",
          description: "Grocery shopping, dry cleaning, retrieval and delivery, and more.",
          icon: errands,
        },
        { 
          name: "Home Care",
          description: "House sitting, plant watering and anything else that Patron's may want help with around the house.",
          icon: houseAndPet,
        },
        { 
          name: "Pet Care",
          description: "Pet sitting, pet walking, pet feeding, and any other pet related activities.",
          icon: pets,
        },
        { 
          name: "Specialty Assistance",
          description: "Tech help, tutoring, photography and design, music lessons, and more.",
          icon: specialty,
        },
        { 
          name: "Winter Snow Shoveling",
          description: "Winter storm snow-shoveling",
          icon: shoveling,
        },
        { 
          name: "Other",
          description: "If a specific service Patron's require is not listed here and not on our exclusions list, Patron's will still inquire with us and will be categorized as other.",
          icon: other,
        },
    ],
}

export const patronDash = {
    title: "Patron Dashboard",
    subheading: "Welcome to your Dashboard! This is the central hub to all your Bonaloop needs! You can navigate from here to submit jobs, edit jobs, cancel jobs, pay for jobs, monitor and administer your jobs, settings, and details, and you can access your resources from here too!",
}

export const patrons = {
    jobtype: [
        { 
          name: "Lawn and Yard",
          description: "Raking, mowing, mulching, gardening, trimming, and weeding lawn and yards of any size, big or small.",
          icon: lawnAndYard,
        },
        { 
          name: "Moving Assistance",
          description: "Packing, unpacking, loading, unloading, and hauling items and properties of all sizes, big or small.",
          icon: moving,
        },
        { 
          name: "Dirty Work",
          description: "Digging, shoveling, light construction, and you guessed it, anything dirty!",
          icon: dirtyWork,
        },
        { 
          name: "Cleaning",
          description: "Cleaning any type & size of space and general organization.",
          icon: cleaning,
        },
        { 
          name: "Event Assistance",
          description: "Assisting with setting up, supporting, and breaking down events such as private parties, festivals, block parties, and more.",
          icon: events,
        },
        { 
          name: "Painting",
          description: "Interior painting of walls, ceilings, furniture, and more. Exterior is allowed when staining previously unpainted fences, decks and furniture. Please be aware that Loopers may not remove, handle, or apply lead paint.",
          icon: painting,
        },
        { 
          name: "Errands and Delivery",
          description: "Grocery shopping, dry cleaning, retrieval and delivery, and more.",
          icon: errands,
        },
        { 
          name: "Home Care",
          description: "House sitting, plant watering and anything else that we can help out with around the house.",
          icon: houseAndPet,
        },
        { 
          name: "Pet Care",
          description: "Pet sitting, pet walking, pet feeding, and any other pet related activities.",
          icon: pets,
        },
        { 
          name: "Specialty Assistance",
          description: "Tech help, tutoring, photography and design, music lessons, and more.",
          icon: specialty,
        },
        { 
          name: "Winter Snow Shoveling",
          description: "Winter storm snow-shoveling support at request.",
          icon: shoveling,
        },
        { 
          name: "Other",
          description: "If a specific service you require is not listed here and not on our exclusions list, you can always inquire with us and we will always do our best to complete your request.",
          icon: other,
        },
    ],
}

export const socials = {
    instagram: "https://instagram.com/bonaloop.official",
    facebook: "https://facebook.com/bonaloop",
    twitter: "https://twitter.com/bonaloop",
}

export const links = {
    domain: "https://bonaloop.com",
    api: "https://api.bonaloop.com/api",
    ver: "/v1",
    currAPI: "https://api.bonaloop.com/api/v1",
}

export const page = {
    mainHome: "/home",
    patronHome: "/patrons",
    looperHome: "/loopers",
    exclusions: "/exclusions",
    faqs: "/faq",
    help: "/help",
    about: "/about",
    submitajob: "/submitajob",
    patronDash: "/patrons/dash",
    looperDash: "/loopers/dash",
    patronRates: "/patrons/pricing",
    looperRates: "/loopers/payrates",
    pricing: "/pricing", // Admin page combining both rates
    patronIncentives: "/patrons/incentives",
    looperIncentives: "/loopers/incentives",
    incentives: "/incentives", // Admin page combining both incentives
    jobfeed: "/jobfeed",
    onboarding: "/orientation",
    orientation: "/orientation",
    signup: "/signup",
    login: "/login",
    crm: "/crm",
    pma: "https://pma.bonaloop.com",
    adminDash: "/admin",
    notifications: "/notifications",
}

export const tel = {
    local: "tel:+19783475999",
    localText: "Local: (978) 347-5999",
    tollFree: "tel:+18888856045",
    tollFreeText: "Toll-Free: (888) 885-6045"
}

export const email = {
    hello: "mailto:hello@bonaloop.com",
    helloText: "Email: hello@bonaloop.com"
}

export const citizenshipLabels = [
    {ID: 1, Value: "Yes", Label: "US Citizen"},
    {ID: 2, Value: "No", Label: "Not a US Citizen"}
]

export const genderLabels = [
    {ID: 1, Value: "Female", Label: "Female"}, 
    {ID: 2, Value: "Male", Label: "Male"}, 
    {ID: 3, Value: "Other", Label: "Other / Prefer Not To Say"}
]

export const looperTruckLabels = [
    {ID: 1, Value: "Yes", Label: "I have a truck, suv, or sedan that I am open to using on jobs."},
    {ID: 2, Value: "No", Label: "No, I do not have or do not wish to use my vehicle on jobs."}
]

export const outdoorsLabels = [
    {ID: 1, Value: "Yes", Label: "This job will be fully or partially outdoors."},
    {ID: 2, Value: "No", Label: "This is job is fully indoors."}
]

export const solicitationLabels = [
    {ID: 1, Value: "Yes", Label: "I am open to additional contact by Loopers and Bonaloop in the name of being a resource in my industry of work."},
    {ID: 2, Value: "No", Label: "No, I do not want to be an expert resource to Loopers."}
]

export const patronTruckLabels = [
    {ID: 1, Value: "Yes", Label: "This job will require a Looper vehicle."},
    {ID: 2, Value: "No", Label: "This job does not require a Looper vehicle."}
]

export const dates = {
    months: [
        {
            display: "January",
            db: "01"
        },
        {
            display: "February",
            db: "02"
        },
        {
            display: "March",
            db: "03"
        },
        {
            display: "April",
            db: "04"
        },
        {
            display: "May",
            db: "05"
        },
        {
            display: "June",
            db: "06"
        },
        {
            display: "July",
            db: "07"
        },
        {
            display: "August",
            db: "08"
        },
        {
            display: "September",
            db: "09"
        },
        {
            display: "October",
            db: "10"
        },
        {
            display: "November",
            db: "11"
        },
        {
            display: "December",
            db: "12"
        },
    ],
    days: [
        {
            display: "1",
            db: "01"
        },
        {
            display: "2",
            db: "02"
        },
        {
            display: "3",
            db: "03"
        },
        {
            display: "4",
            db: "04"
        },
        {
            display: "5",
            db: "05"
        },
        {
            display: "6",
            db: "06"
        },
        {
            display: "7",
            db: "07"
        },
        {
            display: "8",
            db: "08"
        },
        {
            display: "9",
            db: "09"
        },
        {
            display: "10",
            db: "10"
        },
        {
            display: "11",
            db: "11"
        },
        {
            display: "12",
            db: "12"
        },
        {
            display: "13",
            db: "13"
        },
        {
            display: "14",
            db: "14"
        },
        {
            display: "15",
            db: "15"
        },
        {
            display: "16",
            db: "16"
        },
        {
            display: "17",
            db: "17"
        },
        {
            display: "18",
            db: "18"
        },
        {
            display: "19",
            db: "19"
        },
        {
            display: "20",
            db: "20"
        },
        {
            display: "21",
            db: "21"
        },
        {
            display: "22",
            db: "22"
        },
        {
            display: "23",
            db: "23"
        },
        {
            display: "24",
            db: "24"
        },
        {
            display: "25",
            db: "25"
        },
        {
            display: "26",
            db: "26"
        },
        {
            display: "27",
            db: "27"
        },
        {
            display: "28",
            db: "28"
        },
        {
            display: "29",
            db: "29"
        },
        {
            display: "30",
            db: "30"
        },
        {
            display: "31",
            db: "31"
        },
    ],
    years: [
        {
            display: "2014",
            db: "2014"
        },
        {
            display: "2015",
            db: "2015"
        },
        {
            display: "2016",
            db: "2016"
        },
        {
            display: "2017",
            db: "2017"
        },
        {
            display: "2018",
            db: "2018"
        },
        {
            display: "2019",
            db: "2019"
        },
        {
            display: "2020",
            db: "2020"
        },
        {
            display: "2021",
            db: "2021"
        },
        {
            display: "2022",
            db: "2022"
        },
        {
            display: "2023",
            db: "2023"
        }
    ],
    times: [
        {
            display: "12:00 AM",
            db: "00:00:00"
        },
        {
            display: "12:15 AM",
            db: "00:15:00"
        },
        {
            display: "12:30 AM",
            db: "00:30:00"
        },
        {
            display: "12:45 AM",
            db: "00:45:00"
        },
        {
            display: "1:00 AM",
            db: "01:00:00"
        },
        {
            display: "1:15 AM",
            db: "01:15:00"
        },
        {
            display: "1:30 AM",
            db: "01:30:00"
        },
        {
            display: "1:45 AM",
            db: "01:45:00"
        },
        {
            display: "2:00 AM",
            db: "02:00:00"
        },
        {
            display: "2:15 AM",
            db: "02:15:00"
        },
        {
            display: "2:30 AM",
            db: "02:30:00"
        },
        {
            display: "2:45 AM",
            db: "02:45:00"
        },
        {
            display: "3:00 AM",
            db: "03:00:00"
        },
        {
            display: "3:15 AM",
            db: "03:15:00"
        },
        {
            display: "3:30 AM",
            db: "03:30:00"
        },
        {
            display: "3:45 AM",
            db: "03:45:00"
        },
        {
            display: "4:00 AM",
            db: "04:00:00"
        },
        {
            display: "4:15 AM",
            db: "04:15:00"
        },
        {
            display: "4:30 AM",
            db: "04:30:00"
        },
        {
            display: "4:45 AM",
            db: "04:45:00"
        },
        {
            display: "5:00 AM",
            db: "05:00:00"
        },
        {
            display: "5:15 AM",
            db: "05:15:00"
        },
        {
            display: "5:30 AM",
            db: "05:30:00"
        },
        {
            display: "5:45 AM",
            db: "05:45:00"
        },
        {
            display: "6:00 AM",
            db: "06:00:00"
        },
        {
            display: "6:15 AM",
            db: "06:15:00"
        },
        {
            display: "6:30 AM",
            db: "06:30:00"
        },
        {
            display: "6:45 AM",
            db: "06:45:00"
        },
        {
            display: "7:00 AM",
            db: "07:00:00"
        },
        {
            display: "7:15 AM",
            db: "07:15:00"
        },
        {
            display: "7:30 AM",
            db: "07:30:00"
        },
        {
            display: "7:45 AM",
            db: "07:45:00"
        },
        {
            display: "8:00 AM",
            db: "08:00:00"
        },
        {
            display: "8:15 AM",
            db: "08:15:00"
        },
        {
            display: "8:30 AM",
            db: "08:30:00"
        },
        {
            display: "8:45 AM",
            db: "08:45:00"
        },
        {
            display: "9:00 AM",
            db: "09:00:00"
        },
        {
            display: "9:15 AM",
            db: "09:15:00"
        },
        {
            display: "9:30 AM",
            db: "09:30:00"
        },
        {
            display: "9:45 AM",
            db: "09:45:00"
        },
        {
            display: "10:00 AM",
            db: "10:00:00"
        },
        {
            display: "10:15 AM",
            db: "10:15:00"
        },
        {
            display: "10:30 AM",
            db: "10:30:00"
        },
        {
            display: "10:45 AM",
            db: "10:45:00"
        },
        {
            display: "11:00 AM",
            db: "11:00:00"
        },
        {
            display: "11:15 AM",
            db: "11:15:00"
        },
        {
            display: "11:30 AM",
            db: "11:30:00"
        },
        {
            display: "11:45 AM",
            db: "11:45:00"
        },
        {
            display: "12:00 PM",
            db: "12:00:00"
        },
        {
            display: "12:15 PM",
            db: "12:15:00"
        },
        {
            display: "12:30 PM",
            db: "12:30:00"
        },
        {
            display: "12:45 PM",
            db: "12:45:00"
        },
        {
            display: "1:00 PM",
            db: "13:00:00"
        },
        {
            display: "1:15 PM",
            db: "13:15:00"
        },
        {
            display: "1:30 PM",
            db: "13:30:00"
        },
        {
            display: "1:45 PM",
            db: "13:45:00"
        },
        {
            display: "2:00 PM",
            db: "14:00:00"
        },
        {
            display: "2:15 PM",
            db: "14:15:00"
        },
        {
            display: "2:30 PM",
            db: "14:30:00"
        },
        {
            display: "2:45 PM",
            db: "14:45:00"
        },
        {
            display: "3:00 PM",
            db: "15:00:00"
        },
        {
            display: "3:15 PM",
            db: "15:15:00"
        },
        {
            display: "3:30 PM",
            db: "15:30:00"
        },
        {
            display: "3:45 PM",
            db: "15:45:00"
        },
        {
            display: "4:00 PM",
            db: "16:00:00"
        },
        {
            display: "4:15 PM",
            db: "16:15:00"
        },
        {
            display: "4:30 PM",
            db: "16:30:00"
        },
        {
            display: "4:45 PM",
            db: "16:45:00"
        },
        {
            display: "5:00 PM",
            db: "17:00:00"
        },
        {
            display: "5:15 PM",
            db: "17:15:00"
        },
        {
            display: "5:30 PM",
            db: "17:30:00"
        },
        {
            display: "5:45 PM",
            db: "17:45:00"
        },
        {
            display: "6:00 PM",
            db: "18:00:00"
        },
        {
            display: "6:15 PM",
            db: "18:15:00"
        },
        {
            display: "6:30 PM",
            db: "18:30:00"
        },
        {
            display: "6:45 PM",
            db: "18:45:00"
        },
        {
            display: "7:00 PM",
            db: "19:00:00"
        },
        {
            display: "7:15 PM",
            db: "19:15:00"
        },
        {
            display: "7:30 PM",
            db: "19:30:00"
        },
        {
            display: "7:45 PM",
            db: "19:45:00"
        },
        {
            display: "8:00 PM",
            db: "20:00:00"
        },
        {
            display: "8:15 PM",
            db: "20:15:00"
        },
        {
            display: "8:30 PM",
            db: "20:30:00"
        },
        {
            display: "8:45 PM",
            db: "20:45:00"
        },
        {
            display: "9:00 PM",
            db: "21:00:00"
        },
        {
            display: "9:15 PM",
            db: "21:15:00"
        },
        {
            display: "9:30 PM",
            db: "21:30:00"
        },
        {
            display: "9:45 PM",
            db: "21:45:00"
        },
        {
            display: "10:00 PM",
            db: "22:00:00"
        },
        {
            display: "10:15 PM",
            db: "22:15:00"
        },
        {
            display: "10:30 PM",
            db: "22:30:00"
        },
        {
            display: "10:45 PM",
            db: "22:45:00"
        },
        {
            display: "11:00 PM",
            db: "23:00:00"
        },
        {
            display: "11:15 PM",
            db: "23:15:00"
        },
        {
            display: "11:30 PM",
            db: "23:30:00"
        },
        {
            display: "11:45 PM",
            db: "23:45:00"
        },
    ]
}

export const SEO = {
    default: {
        title: "Bonaloop",
        description: "",
        keywords: "",
        canonical: "https://bonaloop.com",
    },
    robots: {
        allow: "index, follow",
        deny: "noindex, nofollow",
    },
    og: { // Facebook, Google Rich Snippets Testing Tool
        site_name: "Bonaloop",
        type: {
            website: "website",
            article: "article",
            profile: "profile",
        },
        title: "",
        url: "",
        image: "",
        description: "",
        audio: "",
        video: "",
    },
    twitter: {
        card: {
            summary: "summary",
            summary_large_image: "summary_large_image",
        },
        site: "@bonaloop",
        title: "",
        description: "",
        image: "",
        creator: "@bonaloop",
    },
}
