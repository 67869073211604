import React from 'react';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import DynamicNav from '../components/nav';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Logo from '../assets/images/Bonaloop-web-header-logo.png';
import Inf from '../assets/images/Bonaloop-web-header-logo.png';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LargeFooter from '../components/footer/LargeFooter';
import ListGroup from 'react-bootstrap/ListGroup';
import CardGroup from 'react-bootstrap/CardGroup';
import JobTypes from '../components/cards/JobTypes';
import {useState} from 'react';
import ClosedNotice from '../components/alerts/patronClosed';
import PatronClosedModal from '../components/modals/patronClosed';
import ShareToLoopers from '../components/modals/shareToLoopers';
import * as sys from '../config/variables';
import * as bona from '../config/constants';

function Patrons() {
  const [showing, setShowing] = useState(false);
  const [sharing, setSharing] = useState(false);

  const jobTypes = bona.patrons.jobtype.map((jobtype, index) =>
    <Col key={index}><JobTypes key={jobtype.name} name={jobtype.name} description={jobtype.description} icon={jobtype.icon} /></Col>
  );
  return (
    <>
      <Container fluid as="main" className="bg-dark no-border">
        <DynamicNav />
        <ClosedNotice setShowing={setShowing}/>
        <PatronClosedModal showing={showing} setShowing={setShowing} setSharing={setSharing} />
        <ShareToLoopers sharing={sharing} setSharing={setSharing} />
        <Carousel fade>
          <Carousel.Item interval={7000}>
            <Image
              src={Logo}
              alt="First slide"
            />
            <Carousel.Caption>
              <h1>Get your to-do list <span style={{color:'red'}}>done</span>.</h1>
              <p>Our platform provides homeowners, businesses, community members, non-profits, city committees, elderly couples, and anyone else with access to labor, support, and hands-on assistance in a vast array of categories. If you are looking for help accomplishing your to-do's, we're here to help!</p>
              <ButtonGroup size="sm" className="d-inline-flex flex-wrap gap-1">
                <Button className="mx-2" href={bona.page.signup} disabled={sys.closed}>Start Signup</Button>
                <Button className="mx-2" href={bona.page.login} disabled={sys.closed}>Login Now</Button>
              </ButtonGroup>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={7000}>
            <Image
              src={Logo}
              alt="Second slide"
            />
            <Carousel.Caption>
              <h1>Get your to-do list <span style={{color:'red'}}>done</span>.</h1>
              <p>Do you have a project you want some help completing? We've got Loopers ready to help! Check out how we can help you below and open your account today!</p>
              <ButtonGroup size="sm" className="d-inline-flex flex-wrap gap-1">
                <Button className="mx-2" href={bona.page.signup} disabled={sys.closed}>Start Signup</Button>
                <Button className="mx-2" href={bona.page.login} disabled={sys.closed}>Login Now</Button>
              </ButtonGroup>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={10000}>
            <Image
              src={Logo}
              alt="Third slide"
            />
            <Carousel.Caption>
              <h3>Want to learn more about Bonaloop?</h3>
              <p>Click any of the buttons to learn more!</p>
              <ButtonGroup size="sm" className="d-inline-flex flex-wrap gap-1">
                <Button className="mx-2" href="#howitworks">How does it work?</Button>
                <Button className="mx-2" href="#services">What can Loopers do?</Button>
                <Button className="mx-2" href="#makeadiff">How can I make a difference?</Button>
              </ButtonGroup>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
        <Container fluid className="bg-danger mt-4">
          <Container fluid className="bg-danger">
            <Card border="danger" bg="danger" text="dark" id="howitworks">
              <Card.Header as="h1" className="my-4">How does it work?</Card.Header>
              <Card.Body className="px-0 mx-0">
                <CardGroup className="text-center d-inline-flex flex-wrap gap-2">
                  <Card border="light" bg="dark" text="light">
                    <Card.Header as="h5">Loopers are...</Card.Header>
                    <Card.Body>
                      <Card.Title as="h3">College Students</Card.Title>
                      <Card.Text>Ranging from recently accepted students all the way to recently graduated students, fully insured Loopers from your local area are here to help! Create or sign in to your account to post today!</Card.Text>
                      <Button href={bona.page.signup} className="m-2" disabled={sys.closed}>Sign Up</Button>
                      <Button href={bona.page.login} className="m-2" disabled={sys.closed}>Sign In</Button>
                    </Card.Body>
                  </Card>
                  <Card border="light" bg="dark" text="light">
                    <Card.Header as="h5">Loopers provide...</Card.Header>
                    <Card.Body>
                      <Card.Title as="h3">Service</Card.Title>
                      <Card.Text>Loopers sign up to assist you on the jobs you post on our feed in all their areas of interest.  To see what areas of interest you can post in, keep scrolling!</Card.Text>
                      <Card.Text>- OR -</Card.Text>
                      <Card.Link href="#services" className="text-white">Click Here to Jump!</Card.Link>
                    </Card.Body>
                  </Card>
                  <Card border="light" bg="dark" text="light">
                    <Card.Header as="h5">Loopers receive...</Card.Header>
                    <Card.Body>
                      <Card.Title as="h3">Compensation</Card.Title>
                      <Card.Text>At the conclusion of the job, Patrons sign back in and confirm that all went well and give the Loopers ratings.  Following that, they pay right on the site for services rendered and can tip however generously they'd like! We take care of everything else!</Card.Text>
                    </Card.Body>
                  </Card>
                </CardGroup>
                <Card border="light" bg="dark" text="light" className="mt-5 mx-0 px-0">
                  <Card.Header as="h5" className="text-center fw-bold">Expectations</Card.Header>
                  <Card.Body className="d-inline-flex flex-wrap justify-content-between gap-3 m-0 p-3">
                    <Card border="dark" bg="dark" text="light" className="m-0 p-0 w-100">
                      <Card.Title className="mt-2 text-center">Loopers...</Card.Title>
                      <Card.Body className="p-0 m-0">
                        <ListGroup variant="flush" className="rounded-3 m-0 p-0">
                          <ListGroup.Item variant="dark">Are 'able bodies', not professionals.  They are not picking up gardening jobs because they are landscapers.  They are doing that because they are comfortable assisting you with your garden.  If they have any experience doing what you've asked of them, it's only a bonus and should not be your expectation.</ListGroup.Item>
                          <ListGroup.Item variant="dark">Are active students.  Given that, they have busy schedules and only pick up jobs from the feed when they can around their availability.  The longer you can list a job on the feed, the more exposure it will have and the higher your chances of a Looper seizing the opportunity.</ListGroup.Item>
                          <ListGroup.Item variant="dark">Are not required to bring their own tools to the job.  It is the Patron's responsibility to have all materials, inclusive of tools, ready by the scheduled start of the job.</ListGroup.Item>
                        </ListGroup>
                      </Card.Body>
                    </Card>
                    <Card border="dark" bg="dark" text="light" className="w-100 m-0 p-0">
                      <Card.Title className="mt-2 text-center">Bonaloop...</Card.Title>
                      <Card.Body className="m-0 p-0">
                        <ListGroup variant="flush" className="rounded-3 m-0 p-0">
                          <ListGroup.Item variant="dark">Will fully insure all jobs and Loopers on those jobs</ListGroup.Item>
                          <ListGroup.Item variant="dark">Promotes jobs on the feed on an as needed basis to provide incentivization to Loopers</ListGroup.Item>
                          <ListGroup.Item variant="dark">Will mainly communicate via email with special circumstances sometimes requiring the use of texting</ListGroup.Item>
                          <ListGroup.Item variant="dark">Requires you have an account through the site in order for you to post</ListGroup.Item>
                          <ListGroup.Item variant="dark">Will facilitate all communication between Loopers and Patrons for privacy reasons</ListGroup.Item>
                          <ListGroup.Item variant="dark">Expects Patrons to respect the Looper's time by not making untimely cancellations</ListGroup.Item>
                        </ListGroup>
                      </Card.Body>
                    </Card>
                  </Card.Body>
                </Card>
              </Card.Body>
            </Card>
          </Container>
        </Container>
        <Container fluid className="bg-dark text-white no-border mt-4">
          <Card border="dark" className="bg-dark" id="services">
            <Card.Header as="h1">What do Loopers do?</Card.Header>
            <Card.Title as="span" className="mt-4 text-center">
              Loopers commonly pick up contracts with local Patrons in the following job types.
            </Card.Title>
            <Card.Subtitle className="text-muted text-center">
              Pay per hour varies on job type and is available for you to review right from the navigation bar on our Pricing page.
            </Card.Subtitle>
            <Card.Body>
              <Row xs={1} md={2} lg={3} xl={3} className="g-4 mt-3">
                {jobTypes}
              </Row>
            </Card.Body>
          </Card>
        </Container>
        <Container fluid className="bg-danger">
          <Container fluid className="bg-danger pb-5" id="makeadiff">
            <h1 className="py-5 mx-4">How can I make a difference?</h1>
            <Card border="dark" bg="dark" text="dark" className="mt-4 rounded-0">
              <Card.Body>
                <CardGroup className="text-center">
                  <Card border="dark" bg="dark" text="light" className="mx-1">
                    <Card.Header as="h3">Sign In</Card.Header>
                    <Card.Body className="bg-white text-black rounded-3">
                      <Card.Text>If you have any needs that our Loopers can assist you with, create an account with us or if you already have an account, sign back in and start posting jobs today! Every job helps local college students stay financially independent and helps you to get that to-do list done.</Card.Text>
                    </Card.Body>
                  </Card>
                  <Card border="dark" bg="dark" text="light" className="mx-1">
                    <Card.Header as="h3">Leave a Tip</Card.Header>
                    <Card.Body className="bg-white text-black rounded-3">
                      <Card.Text>Bonaloop charges well below the median costs for most services because we cannot offer any professionals in any industry and because Loopers do not have any possibly required equipment.  We only offer helping hands.  Our pricing reflects that.  In addition to those competitive rates, Bonaloop has to take a fixed amount of the hourly rate per job to pay for insurances and overhead.  While our Loopers do still make well above minimum wage, tips will always generate a smile.  If it is within your means, please tip Loopers well.  They work hard! </Card.Text>
                    </Card.Body>
                  </Card>
                  <Card border="dark" bg="dark" text="light" className="mx-1">
                    <Card.Header as="h3">Incentivize</Card.Header>
                    <Card.Body className="bg-white text-black rounded-3">
                      <Card.Text>Despite our best attempts, during the final weeks of each semester, transitional periods between semesters, and often randomly due to other factors, Loopers may refrain from picking up jobs.  Bonaloop has implemented an incentive field in requests so you can add a little extra money to the job that Loopers get in addition to the base pay simply for picking up the job.  This has statistically increased the chances of Loopers picking up jobs, even in those lull periods.  We highly recommend you take advantage of it!</Card.Text>
                    </Card.Body>
                  </Card>
                </CardGroup>
                <Card border="dark" bg="dark" text="light" className="mx-0 px-0 mt-5">
                  <Card.Header as="h3" className="text-center">Additional Ways To Help</Card.Header>
                  <Card.Body className="px-0 mx-0">
                    <ListGroup className="justify-content-end text-start rounded-3 mx-0 px-0" variant="flush">
                        <ListGroup.Item>
                          <h3>Expert Resource</h3>
                          <ListGroup variant="flush">
                            <ListGroup.Item>When you sign up with us, we give you the chance to disclose what you do for work and where.  This is completely optional but should you choose to disclose that information, you may be solicited to be a resource for any Loopers who may need a temporary resource in that industry.  This can be invaluable for Loopers who may need additional help.</ListGroup.Item>
                            <ListGroup.Item>In addition to one on one networking, we often host events for multiple Loopers to meet with experts in their fields and we are always looking for more volunteers.  If you disclose during your sign up that you are open to these opportunities, you may be solicited to take part in a future meetup.</ListGroup.Item>
                          </ListGroup>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <h3>Invest</h3>
                          <ListGroup variant="flush">
                            <ListGroup.Item>Bonaloop is a start up.  We were founded in 2014 and our founding communities have taken kindly to our vision and our mission.  Unfortunately, the pandemic has wreaked havoc on our Looper base and revenue and we are severely unable to be the strong resource we once were for our Loopers and their communities.</ListGroup.Item>
                            <ListGroup.Item>If you have the means to help keep the business alive, consider donating.  Bonaloop not only wants to keep serving our founding communities but we hope to one day expand nation-wide! Every bit of business and every donation helps!</ListGroup.Item>
                          </ListGroup>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <h3>Recruit</h3>
                          <ListGroup variant="flush">
                            <ListGroup.Item>As mentioned, our Looper base is limited and we are always in constant need of new Loopers.  The more Loopers we have, the higher the chances of a Looper grabbing your listing and the more Loopers we can be a resource to.</ListGroup.Item>
                            <ListGroup.Item>If you know of any college students, whether soon to be or just graduated, family, friend, acquaintance, or stranger, please send them our way so they can know of us and hopefully sign up!</ListGroup.Item>
                          </ListGroup>
                        </ListGroup.Item>
                    </ListGroup>
                  </Card.Body>
                </Card>
              </Card.Body>
            </Card>
          </Container>
        </Container>
        <Container fluid className="bg-dark text-white my-5 text-center">
          <Card border="dark" bg="dark" text="danger" className="pt-2" id="cta">
            <Card.Img variant="top" src={Inf} style={{width: '200px'}} className="mx-auto align-center" />
            <Card.Title as="h1" className="mt-5">Ready to post a job? Join the Bonaloop community now!</Card.Title>
            <Card.Body>
                <Card.Text as="h3" className="mb-5">Get started!</Card.Text>
                <Button size="lg" href={bona.page.signup} className="m-2" disabled={sys.closed}>Click Here to Sign Up</Button>
                <Button size="lg" href={bona.page.login} className="m-2" disabled={sys.closed}>Click Here to Sign In</Button>
            </Card.Body>
          </Card>
        </Container>
        <LargeFooter />
      </Container>
    </>
  );
}

export default Patrons;