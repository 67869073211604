import React from 'react';
import Inf from '../../assets/images/Bonaloop-web-header-logo.png';
import facebook from '../../assets/svg/facebook.svg';
import twitter from '../../assets/svg/twitter.svg';
import instagram from '../../assets/svg/instagram.svg';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import * as bona from '../../config/constants';
import * as sys from '../../config/variables';

function LargeFooter() {
  return (
    <>
        <Container fluid as="footer" className="bg-light pt-5">
            <Row>
                <Col className="col-6 col-md-2 mb-3">
                    <Card border='light'>
                        <Card.Title as="h5">Bonaloop</Card.Title>
                        <ListGroup className="nav">
                            <ListGroup.Item className="nav-item mb-2 border-white nav-link text-muted" action href={bona.page.mainHome}>Home</ListGroup.Item>
                            <ListGroup.Item className="nav-item mb-2 border-white nav-link text-muted" action href={bona.page.exclusions}>Exclusions</ListGroup.Item>
                            <ListGroup.Item className="nav-item mb-2 border-white nav-link text-muted" action href={bona.page.faqs}>FAQs</ListGroup.Item>
                            <ListGroup.Item className="nav-item mb-2 border-white nav-link text-muted" action disabled={sys.closed} href={bona.page.help}>Help</ListGroup.Item>
                            <ListGroup.Item className="nav-item mb-2 border-white nav-link text-muted" action href={bona.page.about}>About</ListGroup.Item>
                        </ListGroup>
                    </Card>
                </Col>
                <Col className="col-6 col-md-2 mb-3">
                    <Card border="light">
                        <Card.Title as="h5">Patrons</Card.Title>
                        <ListGroup className="nav flex-column">
                            <ListGroup.Item className="nav-item mb-2 border-white nav-link text-muted" action href={bona.page.patronHome}>Patron Home</ListGroup.Item>
                            <ListGroup.Item className="nav-item mb-2 border-white nav-link text-muted" action disabled={!sys.jobSubmission || sys.maintenance} href={bona.page.submitajob}>New Job</ListGroup.Item>
                            <ListGroup.Item className="nav-item mb-2 border-white nav-link text-muted" action href={bona.page.patronDash}>Dashboard</ListGroup.Item>
                            <ListGroup.Item className="nav-item mb-2 border-white nav-link text-muted" action href={bona.page.patronRates}>Pricing</ListGroup.Item>
                            <ListGroup.Item className="nav-item mb-2 border-white nav-link text-muted" action href={bona.page.patronIncentives}>Incentives</ListGroup.Item>
                        </ListGroup>
                    </Card>
                </Col>
                <Col className="col-6 col-md-2 mb-3">
                    <Card border="light">
                        <Card.Title as="h5">Loopers</Card.Title>
                        <ListGroup className="nav flex-column">
                            <ListGroup.Item className="nav-item mb-2 border-white nav-link text-muted" action href={bona.page.looperHome}>Looper Home</ListGroup.Item>
                            <ListGroup.Item className="nav-item mb-2 border-white nav-link text-muted" action disabled={!sys.jobFeed || sys.maintenance} href={bona.page.jobfeed}>Job Feed</ListGroup.Item>
                            <ListGroup.Item className="nav-item mb-2 border-white nav-link text-muted" action href={bona.page.looperDash}>Dashboard</ListGroup.Item>
                            <ListGroup.Item className="nav-item mb-2 border-white nav-link text-muted" action href={bona.page.looperRates}>Pay Rates</ListGroup.Item>
                            <ListGroup.Item className="nav-item mb-2 border-white nav-link text-muted" action href={bona.page.looperIncentives}>Incentives</ListGroup.Item>
                        </ListGroup>
                    </Card>
                </Col>
                <Col className="col-6 col-md-2 mb-3">
                    <Card border="light">
                        <Card.Title as="h5">Contact Us</Card.Title>
                        <ListGroup className="nav flex-column" variant="flush">
                            <ListGroup.Item className="nav-item mb-2 border-white nav-link text-muted" action href={bona.tel.tollFree}>{bona.tel.tollFreeText}</ListGroup.Item>
                            <ListGroup.Item className="nav-item mb-2 border-white nav-link text-muted" action href={bona.tel.local}>{bona.tel.localText}</ListGroup.Item>
                            <ListGroup.Item className="nav-item mb-2 border-white nav-link text-muted" action href={bona.email.hello}>{bona.email.helloText}</ListGroup.Item>
                        </ListGroup>
                    </Card>
                </Col>
                <Col>
                    <Form>
                        <Form.Label as="h5">Subscribe to our newsletter</Form.Label>
                        <Form.Text as="p">Monthly digest of what's new and exciting from us.</Form.Text>
                        <Form.Group className="d-flex flex-column flex-sm-row w-100 gap-2">
                            <Form.Label className="visually-hidden">Email address</Form.Label>
                            <Form.Control type="email" className="form-control" placeholder="Email address"/>
                            <Button className="btn btn-primary" type="submit">Subscribe</Button>
                        </Form.Group>
                    </Form>
                    
                </Col>
            </Row>
            <Row className="d-flex flex-column flex-sm-row justify-content-between py-3 border-top">
                <Col className="text-muted d-flex align-items-center">
                    <Image className="mx-2" src={Inf} alt="Bonaloop Logo" height="30" width="30" />
                    &copy; {(new Date().getFullYear())} Bonaloop, LLC
                </Col>
                <Col xs="auto">
                    <ListGroup as="ul" horizontal>
                        <ListGroup.Item as="li" action href={bona.socials.facebook}>
                            <Image src={facebook} alt="Bonaloop Facebook" height="25" width="25"/>
                        </ListGroup.Item>
                        <ListGroup.Item as="li" action href={bona.socials.twitter}>
                            <Image src={twitter} alt="Bonaloop Twitter" height="25" width="25" />
                        </ListGroup.Item>
                        <ListGroup.Item as="li" action href={bona.socials.instagram}>
                            <Image src={instagram} alt="Bonaloop Instagram" height="25" width="25" />
                        </ListGroup.Item>
                    </ListGroup>        
                </Col> 
            </Row>
        </Container>
    </>
  );
}

export default LargeFooter;







