import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';

function FormJobSubmitReview(props) {
    const values = props.values;
    const mappedDates = values.dates.map((slot) => {
        switch (slot.dateType) {
            case 1:
                return (
                    <ListGroup.Item key={slot.index}>
                        <ListGroup variant="flush">
                            <ListGroup.Item>
                                <u>Specific Availability</u><br />
                                <b>Date: </b>{slot.date}<br />
                                <b>Start Time: </b>{slot.startTime}<br />
                                <b>Approx. End: </b>{slot.endTime}
                            </ListGroup.Item>
                        </ListGroup>
                    </ListGroup.Item>
                );
            
            case 2:
                return (
                    <ListGroup.Item key={slot.index}>
                        <ListGroup variant="flush">
                            <ListGroup.Item>
                                <u>Window of Availability</u><br />
                                <b>Date: </b>{slot.date}<br />
                                <b>Start Time: </b>{slot.startTime}<br />
                                <b>Approx. End: </b>{slot.endTime}
                            </ListGroup.Item>
                        </ListGroup>
                    </ListGroup.Item>
                );
            
            default:
                return (
                    <h4>No dates to show</h4>
                );
        }
    });

    return (
        <>
            <Form.Label className="px-1 fw-bold text-center mb-3">Review the information you provided below before clicking submit.</Form.Label>
            <ListGroup variant="flush" className="mb-3">
                <ListGroup.Item><b>Job Type: </b>{values.jobTypeName}</ListGroup.Item>
                <ListGroup.Item><b>Job Description: </b>{values.jobDescription}</ListGroup.Item>
                <ListGroup.Item><b>Upon Arrival Notes: </b>{values.uponArrival}</ListGroup.Item>
                <ListGroup.Item><b>Number of Loopers Requested: </b>{values.preferredNumOfLoopers}</ListGroup.Item>
                <ListGroup.Item><b>Slots of Availability</b><ListGroup className="m-1" style={{ overflowX: 'auto'}} horizontal>{mappedDates}</ListGroup></ListGroup.Item>
                <ListGroup.Item><b>Outdoors: </b>{values.outdoors} <b>| Vehicle: </b>{values.truck}</ListGroup.Item>
                <ListGroup.Item><b>Incentive for Loopers to pick up job: </b>${values.incentive}</ListGroup.Item>
                <ListGroup.Item><b>Job Address: </b>{values.jobAddress} <b>| </b>{values.jobAddressTypeName}</ListGroup.Item>
                <ListGroup.Item><b>Job Phone: </b>{values.jobPhone} <b>| </b>{values.jobPhoneTypeName}</ListGroup.Item>
            </ListGroup>
            <Form.Label className="px-1 fw-bold text-center mb-3">If all is correct, click the submit button below!</Form.Label>
        </>
    );
}

export default FormJobSubmitReview;