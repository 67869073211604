import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import * as bona from '../../config/constants';
import * as sys from '../../config/variables';

function LooperClosed (props) {
    return (
        <Modal show={props.showing} onHide={() => props.setShowing(false)} size="lg" centered>
            <Modal.Header className="jusify-content-between h4" closeButton>Welcome to Bonaloop!</Modal.Header>
            <Modal.Body>
                <p className="h5">Interested in joining our community?</p>
                <p>We are actively looking for more Loopers in your community! To learn more about what it means to be a Looper, click learn more down below.  If you're ready to join us, click sign up down below!</p><p className='h5'>NOTE: Picking up jobs in your area is unavailable at this time.</p><p>Picking up jobs will return when we have enough Loopers to handle demands so get out there and tell your friends to sign up!</p>
            </Modal.Body>
            <Modal.Footer className="justify-content-around">
                <Button href={bona.page.signup} variant="link" disabled={!sys.looperRegistration || sys.maintenance}>Sign Up Here!</Button>
                <Button onClick={() => props.setShowing(false)} variant="link">Learn More!</Button>
                <Button onClick={() => {props.setShowing(false); props.setSharing(true)}} variant="link">Share This!</Button>
                <span>{bona.looperHome.modalClosedSpanText}</span>
            </Modal.Footer>
        </Modal>
    );
}

export default LooperClosed;