import React, {useState} from 'react';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import Card from 'react-bootstrap/Card';
import * as bona from '../../../config/constants';
import * as sys from '../../../config/variables';

function FormPhoneTypePicker(props) {
    const [types, setTypes] = useState([]);
    
    const fetchPhoneTypes = async () => {
        if (!sys.maintenance) {
            const response = await fetch(`${bona.links.currAPI}/phones/types/all`);
            const typesIn = await response.json();
            setTypes(typesIn);
        }
    };

    const ddoutput = types.map(key => {
        return (
            <Dropdown.Item key={key.typeID} eventKey={key.name} onClick={() => props.setPhoneTypeID(key.typeID)}>{`${key.name}`}</Dropdown.Item>
        );
    });

    return (
        <>
            <Card border="light" className="my-3" onClick={fetchPhoneTypes}>
                <Form.Group className="mb-3">
                    <Form.Label className="px-1 fw-bold">{props.label}</Form.Label>
                    <Dropdown aria-label={props.label} onSelect={(e) => props.setPhoneTypeName(e)} value={props.value}>
                        <Dropdown.Toggle variant="secondary">{props.showMe || props.instructions}</Dropdown.Toggle>
                        <Dropdown.Menu variant="secondary" className="overflow-scroll" style={{maxHeight: "200px"}}>{ddoutput}</Dropdown.Menu>
                    </Dropdown>
                    <Form.Text className="text-muted">{`Selected Phone Type: ${props.showMe}`}</Form.Text>
                </Form.Group>
            </Card>
        </>
    );
}

export default FormPhoneTypePicker;