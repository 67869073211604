import Container from 'react-bootstrap/Container';
import DynamicNav from '../components/nav';
import CommonIntro from '../components/intros/CommonIntro';
import LargeFooter from '../components/footer/LargeFooter';
import useDocumentTitle from '../functions/useDocumentTitle';
import Nav from 'react-bootstrap/Nav';

function PatronOnboarding () {
    useDocumentTitle('Dashboard | Bonaloop');
    const title = "Patron Dashboard";
    const subheading = "Welcome to your Dashboard! This is the central hub to all your Bonaloop needs! You can navigate from here to submit jobs, edit jobs, cancel jobs, pay for jobs, monitor and administer your jobs, settings, and details, and you can access your resources from here too!";

    return (
        <>
            <Container fluid as="main" className="bg-dark">
                <DynamicNav />
                <Container fluid className="bg-dark">
                    <CommonIntro title={title} subheading={subheading} />
                </Container>
                <Container className="bg-dark my-4">
                    <Nav fill variant="tabs" defaultActiveKey="Paperwork">
                        <Nav.Item>
                            <Nav.Link eventKey="Paperwork">Paperwork</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="HowTo">How To</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="AboutUs">About Us</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="Resources">Resources</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Container>
                <LargeFooter />
            </Container>
        </>
  );
}

export default PatronOnboarding;