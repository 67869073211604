import React from 'react';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Card from 'react-bootstrap/Card';
import instagram from '../../assets/svg/instagram.svg';
import {useLocation} from 'react-router-dom';
import * as bona from '../../config/constants';
import * as sys from '../../config/variables';

function JobTypes(jobtype) {
    const location = useLocation();
    return (
        <>
            <Card key={jobtype.name} border="light" className="text-white bg-dark">
                <Card.Img variant="top" src={jobtype.icon} />
                <Card.Body>
                    <Card.Title>{jobtype.name}</Card.Title>
                    <Card.Text style={{height: '10vh', overflowY: 'auto'}}>{jobtype.description}</Card.Text>
                    <ButtonToolbar className="justify-content-around">
                        <ButtonGroup className="my-2">
                            <Button variant="secondary text-black" href={bona.socials.instagram}>View on <Image src={instagram} alt="Bonaloop Instagram" height="25" width="25" className="ms-1" style={{color: 'white'}}></Image></Button>
                            <DropdownButton as={ButtonGroup} title="Browse Jobs">
                                {location.pathname === bona.page.looperHome && <Dropdown.Item eventKey="signup" href={bona.page.signup} disabled={!sys.looperRegistration}>I need to sign up!</Dropdown.Item>}
                                {location.pathname === bona.page.patronHome && <Dropdown.Item eventKey="signup" href={bona.page.signup} disabled={!sys.patronRegistration}>I need to sign up!</Dropdown.Item>}
                                <Dropdown.Item eventKey="login" href={bona.page.login} disabled={sys.closed}>I need to sign in!</Dropdown.Item>
                                {location.pathname === bona.page.looperHome && <Dropdown.Item eventKey="feed" href={bona.page.jobfeed} disabled={sys.closed || !sys.jobFeed}>Take me to the feed!</Dropdown.Item>}
                                {location.pathname === bona.page.patronHome && <Dropdown.Item eventKey="submitajob" href={bona.page.submitajob} disabled={sys.closed || !sys.jobSubmission}>I want to submit a job!</Dropdown.Item>}
                            </DropdownButton>
                        </ButtonGroup>
                    </ButtonToolbar>
                </Card.Body>
            </Card>
        </>
    );
}

export default JobTypes;