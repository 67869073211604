import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import * as bona from '../../config/constants';
import * as sys from '../../config/variables';

function PatronClosed (props) {
    if (!sys.closed) {
        return (
            <Alert variant="danger" className="text-center">
                <Alert.Heading>{bona.patronHome.alertClosedHeading}</Alert.Heading>
                <Button onClick={() => props.setShowing(true)}>{bona.patronHome.alertClosedCTA}</Button>
            </Alert>
        );
    }
}

export default PatronClosed;