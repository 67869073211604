import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {useRef} from 'react';
import * as sys from '../../config/variables';

function ShareToLoopers (props) {
    const fromName = useRef(null);
    const toName = useRef(null);
    const toEmail = useRef(null);
    const toPhone = useRef(null);

    let submitForm = (e) => {
        e.preventDefault();
        const sender = fromName.current.value;
        const recipient = toName.current.value;
        const email = toEmail.current.value;
        const phone = toPhone.current.value;
        if (sender && recipient && sender !== "" && recipient !== "" && ((email || phone) && (email !== "" || phone !== ""))) {
            if (email !== "" && !phone) {
                console.log(email, "No Phone");
            }
            if (phone !== "" && !email) {
                console.log(phone, "No Email");
            }
            if (email !== "" && phone !== "") {
                console.log(email, phone);
            }
            if (!email && !phone) {
                console.log("No destination entry was made");
            }
        }
        else {
            console.log("Need a sender and a recipient and at least one destination field");
        }
    }

    return (
        <Modal show={props.sharing} onHide={() => props.setSharing(false)} size="lg" centered>
            <Modal.Header className="jusify-content-between h4" closeButton>Share Bonaloop with Students!</Modal.Header>
            <Modal.Body>
                <Form onSubmit={(e) => submitForm(e)} className="d-flex flex-row flex-wrap">    
                    <Form.Group className="mb-3 w-50 p-1">
                        <Form.Label className="fw-bold">What's your name?</Form.Label>
                        <Form.Control ref={fromName} type="text" placeholder="Your name here..." required/>
                        <Form.Text className="text-muted">
                            We're collecting this so that referrals know who referred them!
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3 w-50 p-1">
                        <Form.Label className="fw-bold">What's their name?</Form.Label>
                        <Form.Control ref={toName} type="text" placeholder="Their name here..." required/>
                        <Form.Text className="text-muted">
                            We're collecting this so that referrals know the message is for them!
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3 w-50 p-1">
                        <Form.Label className="fw-bold">Send to an Email Address</Form.Label>
                        <Form.Control ref={toEmail} type="email" placeholder="Email here..." />
                        <Form.Text className="text-muted">
                        We'll never share their email with anyone else.
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3 w-50 p-1">
                        <Form.Label className="fw-bold">Send to a Cell Phone</Form.Label>
                        <Form.Control ref={toPhone} type="tel" placeholder="Mobile number here..." />
                        <Form.Text className="text-muted">
                            We will send this number a text message with the information. We'll never share their number with anyone else.
                        </Form.Text>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className="justify-content-around">
                <Button onClick={e => submitForm(e)} type="submit" variant="primary" className="w-25" disabled={!sys.emailSending}>Send Email</Button>
                <Button onClick={e => submitForm(e)} type="submit" variant="primary" className="w-25" disabled={!sys.smsSending}>Send Text</Button>
                <Button onClick={e => submitForm(e)} type="submit" variant="primary" className="w-25" disabled={!sys.emailSending || !sys.smsSending}>Send Both</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ShareToLoopers;