import Button from 'react-bootstrap/Button';

function FormClusterButton(props) {
    return (
        <>
            <Button className="mx-2 w-100" variant={props.variant} size={props.size} type="submit" onClick={props.onClick} disabled={props.disabled}>{props.label}</Button>
        </>
    );
}

export default FormClusterButton;