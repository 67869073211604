import Form from 'react-bootstrap/Form';
import * as bona from '../../../config/constants';

function FormCitizenship(props) {
    
    const radios = bona.citizenshipLabels.map((label) => {
        return (
            <Form.Check
                inline
                label={label.Label}
                name={props.groupName}
                type="radio"
                key={label.ID}
                onChange={() => props.setInput(label.Value)}
                value={label.Value}
                checked={label.Value === props.value}
            />
        );
    });

    return (
        <>
            <Form.Group key={props.groupName} className="d-flex flex-column mb-3">
                <Form.Label className="px-1 fw-bold">{props.heading}</Form.Label>
                <Form.Group className="d-inline-flex gap-2">{radios}</Form.Group>
                <Form.Text className="text-muted">{props.subText}</Form.Text>
            </Form.Group>
        </>
    );
}

export default FormCitizenship;