import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import {useState} from 'react';

function JobFeedJobCard (props) {
    const [promptToApply, setPromptToApply] = useState(false);

    return (
        <Card key={props.jobID} className="px-0 my-2" style={{width: '34em' }} border="light">
            <Card.Header className="m-0 px-2 py-1">
                <Card.Text className="float-start m-0 p-0"><b>Job ID: </b>{`${props.jobID}`}</Card.Text>
                <Card.Text className="float-end m-0 p-0"><b>Est. Earnings: </b>{`$${props.estimation}`}</Card.Text>
            </Card.Header>
            {promptToApply && 
                <Card.Body className="m-0 px-2 py-1 d-flex flex-column gap-2 justify-content-center">
                    <Card.Title className="pt-1 text-center">Are you sure you want to apply?</Card.Title>
                    <Button variant="danger" className="mt-2" onClick={() => props.apply(props.jobID)}>Apply</Button>
                    <Button variant="success" className="mt-2" onClick={() => setPromptToApply(false)}>Cancel</Button>
                </Card.Body>
            }
            {!promptToApply && 
                <Card.Body className="m-0 px-2 py-1">
                    <Button variant="secondary" className="mt-2 float-end" onClick={() => setPromptToApply(true)}>Apply?</Button>
                    <Card.Title className="pt-1">{`${props.jobType} `}<i>{` ($${props.payRate}/hr)`}</i></Card.Title>
                    <Card.Subtitle className="pt-1 pb-3"><i>{`${props.remaining} spot(s) left!`}</i></Card.Subtitle>
                    <Card.Text className="m-0 pt-1 pb-1"><b>Description: </b>{`${props.jobDescription} (${props.city}, ${props.state})`}</Card.Text>
                    <Card.Text className="m-0 pb-3"><b>Est. Duration: </b>{`${props.duration} hour(s)`}</Card.Text>
                    {props.dateType === 1 && <Card.Text className="m-0 p-0 text-center"><b><i>{`${props.startDate.getMonth() + 1}/${props.startDate.getDate()} at ${props.startTime} to ${props.endTime}`}</i></b></Card.Text>}
                    {props.dateType === 2 && <Card.Text className="m-0 p-0 text-center"><b><i>{`${props.startDate.getMonth() + 1}/${props.startDate.getDate()} anytime ${props.startTime} to ${props.endTime}`}</i></b></Card.Text>}
                </Card.Body>
            }
            {(props.hotJob || props.bonus) && !promptToApply && 
                <Card.Footer className="m-0 px-2 py-1">
                    {props.hotJob && <Card.Text className="float-start m-0 p-0"><b>Hot Job: </b>{`$${props.hotJob}`}</Card.Text>}
                    {props.bonus && <Card.Text className="float-end m-0 p-0"><b>Bonus: </b>{`$${props.bonus}`}</Card.Text>}
                </Card.Footer>
            }
            {promptToApply && 
                <Card.Footer className="m-0 px-2 py-1">
                    {props.dateType === "1" && <Card.Text className="m-0 p-0 text-center"><b><i>{`${props.startDate} at ${props.startTime} to ${props.endTime}`}</i></b></Card.Text>}
                    {props.dateType === "2" && <Card.Text className="m-0 p-0 text-center"><b><i>{`${props.startDate} anytime ${props.startTime} to ${props.endTime}`}</i></b></Card.Text>}
                </Card.Footer>
            }
        </Card>
    );
}

export default JobFeedJobCard;