import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import {useState} from 'react';

function FormLooperSignupReview(props) {
    const values = props.values;
    const passwordIn = props.values.password;
    const concealed = "****************";
    const [password, setPassword] = useState(concealed);

    let showPassword = (e) => {
        e.preventDefault();
        if (password === concealed) { setPassword(passwordIn); }
        else { setPassword(concealed); }
    }

    return (
        <>
            <Form.Label className="px-1 fw-bold text-center mb-3">Review the information you provided below before clicking submit.</Form.Label>
            <ListGroup variant="flush" className="mb-3">
                <ListGroup.Item>{`Name: ${values.firstName} ${values.lastName} `}</ListGroup.Item>
                <ListGroup.Item>{`Phone: ${values.phoneNumber} | ${values.phoneTypeName} with ${values.carrierName}`}</ListGroup.Item>
                <ListGroup.Item>{`Personal Email: ${values.personalEmail}`}<br/>** WE WILL VERIFY THIS NEXT STEP **</ListGroup.Item>
                <ListGroup.Item>{`Attending ${values.schoolName}`}</ListGroup.Item>
                <ListGroup.Item>{`Studying: ${values.major} until ${values.gradYear}`}</ListGroup.Item>
                <ListGroup.Item>{`School Email: ${values.eduEmail}`}<br/>** WE WILL VERIFY THIS NEXT STEP **</ListGroup.Item>
                <ListGroup.Item>{`Gender: ${values.genderName} | Citizenship: ${values.citizenship} | Vehicle: ${values.hasTruck}`}</ListGroup.Item>
                <ListGroup.Item>{`Address: ${values.address} | ${values.addressTypeName}`}</ListGroup.Item>
                <ListGroup.Item action onClick={showPassword}>{`Password: ${password}`}<br/>** Click to view your password **</ListGroup.Item>
                <ListGroup.Item>{`Source: ${values.sourceName} | Referred by: ${values.referralCode}`}</ListGroup.Item>
            </ListGroup>
            <Form.Label className="px-1 fw-bold text-center mb-3">If all is correct, click the submit button below!</Form.Label>
        </>
    );
}

export default FormLooperSignupReview;