import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';
import DynamicNav from '../components/nav';
import CommonIntro from '../components/intros/CommonIntro';
import LargeFooter from '../components/footer/LargeFooter';
import useDocumentTitle from '../functions/useDocumentTitle';
import * as bona from '../config/constants';

function Help () {
  const helpList = bona.help.helpArticles.map((helpArticle, index) =>
    <Accordion.Item key={index} eventKey={helpArticle.topic}>
      <Accordion.Header>{<b>{helpArticle.topic}</b>}</Accordion.Header>
      <Accordion.Body dangerouslySetInnerHTML={{__html:helpArticle.text}}></Accordion.Body>
      <Accordion.Body className="text-center" dangerouslySetInnerHTML={{__html:helpArticle.video}}></Accordion.Body>
    </Accordion.Item>
  );
  
  useDocumentTitle('Help | Bonaloop');
  
  return (
    <>
      <Container fluid as="main" className="bg-dark">
        <DynamicNav />
        <Container fluid className="bg-dark">
          <CommonIntro title={bona.help.title} subheading={bona.help.subheading} />
        </Container>
        <Container className="bg-dark my-4">
          <Accordion flush>{helpList}</Accordion>
        </Container>
        <LargeFooter />
      </Container>
    </>
  );
}

export default Help;